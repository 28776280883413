import React from "react";
import { useLocation } from "react-router-dom";
import EditorForm from "../../components/form/EditorForm";

const Write = () => {
  const { pathname } = useLocation();

  const title = () => {
    if (pathname === "/info/termswrite") {
      return "이용약관 등록하기";
    }
    if (pathname === "/info/policywrite") {
      return "개인정보처리방침 등록하기";
    }
    if (pathname === "/info/agreewrite") {
      return "개인정보수집동의 등록하기";
    }
  };

  return (
    <div>
      <EditorForm title={title()} />
    </div>
  );
};

export default Write;
