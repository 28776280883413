import { AdminChatMessage } from "../../entities/entities/AdminChatMessage";
import dayjs from "dayjs"
import { Link } from "react-router-dom";

const Message = ({
  chatData,
}: {
  chatData: AdminChatMessage[] | undefined;
}) => {
  return (
    <div>
      <div className="text-[#333] mt-[70px] w-[1050px]">
        <div className="flex items-center justify-between">
          <div className="page-sub-tit mb-4" style={{ color: "#333" }}>
            문의 응답하기
          </div>
          <Link
            to="/communication/qna"
            className="text-[#666] font-semibold text-[15px] cursor-pointer"
          >
            더보기 〉
          </Link>
        </div>

        <div className="w-full border-t-[1.5px] border-[#333] bg-[#F9FCFF] max-h-[1200px] overflow-y-scroll">
          <div className="border-b-[1.5px] border-[#CFCFCF] text-sm font-medium">
            <div className="pl-7 flex items-center justify-evenly py-2">
              <div className="w-[20%]">문의자</div>
              <div className="w-[65%]">문의내용</div>
              <div className="w-[15%]"></div>
            </div>
          </div>
          <div className="text-sm font-medium">
            {chatData?.map((item) => (
              <div
                key={item.id}
                className="pl-7 flex items-center justify-evenly py-4 border-b-4 border-[#E7E7E7] last:border-none"
              >
                <div className="w-[20%] text-base font-semibold">
                  {item.memberId || item.clinicMemberId}
                </div>
                <div className="w-[65%] text-[#666] font-medium text-[13px] ellipsis">
                  {item.message}
                </div>
                <div className="w-[15%] text-xs font-medium text-[#666] flex items-center justify-center">
                  {dayjs(item.regDate).format("HH:mm")}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
