import { Column, Entity } from "typeorm";

@Entity("member_login_count", { schema: "dentin" })
export class MemberLoginCount {
  @Column("date", { primary: true, name: "proc_date", comment: "처리일" })
  procDate: string;

  @Column("tinyint", {
    primary: true,
    name: "member_type",
    comment: "회원 유형",
  })
  memberType: number;

  @Column("int", { name: "login_count", comment: "로그인 수" })
  loginCount: number;
}
