import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { login, __getDefaultHeader } from "../services/auth/auth.service";
import { tokenState } from "../state";

export default function Login() {
  const [id, setId] = useState<string>("");
  const [pw, setPw] = useState<string>("");
  const [fail, setFail] = useState<boolean>(false);
  const setToken = useSetRecoilState(tokenState);
  const navigate = useNavigate();

  const submit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const token = await login(id, pw);

      setToken(token);
      setFail(false);

      navigate("/");
    } catch (e) {
      setFail(true);
    }
  };

  return (
    <div className="bg-[#E5E5E5] fixed top-0 left-0 w-full h-full">
      <form className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
        <div className="flex items-end">
          <img src="/logo.svg" alt="logo" />
          <div className="text-main ml-5">ADMIN LOGIN</div>
        </div>
        <div className="text-[#808080] mt-3 mb-8">
          구인구직부터 온라인 교육까지, 치과인!
        </div>
        <input
          className="text-input mb-3"
          type="text"
          placeholder="아이디 입력"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <input
          className="text-input"
          type="password"
          placeholder="비밀번호 입력"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
        />
        {fail && (
          <div className="text-[#E00930] text-sm self-end mt-2">
            아이디 또는 비밀번호를 확인해주세요.
          </div>
        )}
        <button className="blue mt-10" style={{ width: 380 }} onClick={submit}>
          관리자 로그인
        </button>
      </form>
    </div>
  );
}
