import { Column, Entity, Index, PrimaryGeneratedColumn } from "typeorm";

@Index("idx_upload_file_info_filename", ["filename"], { unique: true })
@Index("idx_upload_file_info_1", ["memberType", "memberId"], {})
@Index("idx_upload_file_reg_date", ["regDate"], {})
@Entity("upload_file_info", { schema: "dentin" })
export class UploadFileInfo {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "file_id",
    comment: "파일 정보 ID",
  })
  fileId: string;

  @Column("tinyint", {
    name: "member_type",
    comment: "회원 유형 (1: 개인회원, 2: 병원 회원, 3: 관리자 )",
  })
  memberType: number;

  @Column("varchar", { name: "member_id", comment: "회원 ID", length: 32 })
  memberId: string;

  @Column("varchar", {
    name: "filename",
    unique: true,
    comment: "저장된 파일명",
    length: 256,
  })
  filename: string;

  @Column("varchar", {
    name: "upload_filename",
    nullable: true,
    comment: "업로드 파일명",
    length: 256,
  })
  uploadFilename: string | null;

  @Column("datetime", { name: "reg_date", comment: "업로드 일시" })
  regDate: Date;
}
