import { Column, Entity, PrimaryGeneratedColumn } from "typeorm";

@Entity("banner", { schema: "dentin" })
export class Banner {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "banner_id",
    comment: "배너 아이디",
  })
  bannerId: string;

  @Column("tinyint", { name: "banner_type", comment: "배너 유형" })
  bannerType: number;

  @Column("varchar", {
    name: "image_filename",
    comment: "배너 이미지 파일명",
    length: 256,
  })
  imageFilename: string;

  @Column("varchar", {
    name: "banner_url",
    nullable: true,
    comment: "배너 URL",
    length: 256,
  })
  bannerUrl: string | null;

  @Column("datetime", { name: "start_date", comment: "시작일" })
  startDate: Date;

  @Column("datetime", { name: "end_date", nullable: true, comment: "종료일" })
  endDate: Date | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;
}
