import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TermsTable from "../../components/info/TermsTable";
import { EntityService, PolicyType } from "../../services/entity.service";
import { Policy } from "../../entities/entities/Policy";

const AgreePage = () => {
  const entityService = new EntityService();
  const [agreeAllData, setAgreeAllData] = useState<
    (number | Policy[])[] | null
  >();
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location.search);
  const [checkItems, setCheckItems] = useState<string[]>([]);
  const page = Number(param.get("page")) || 1;
  const cate = param.get("cate") || "member";

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchPolicies(
        page,
        20,
        PolicyType.PRIVACY_USAGE_AGREEMENT,
        cate === "member" ? 1 : 2
      );
      setAgreeAllData(res);
    })();
  }, [page, cate]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/info/terms?page=${selected}`);
  };

  const deleteList = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        await entityService.deleteMultiples(Policy, "policyId", checkItems);
        alert("삭제가 완료되었습니다.");
        window.location.reload();
      } catch (e) {
        console.log(e);
        alert("에러가 발생했습니다.");
      }
    }
  };

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">개인정보수집동의</div>
        <div className="space-x-2">
          <button
            className="blue"
            onClick={() => navigate("/info/agreewrite", { state: cate })}
          >
            등록하기
          </button>
          <button onClick={deleteList}>선택삭제</button>
        </div>
      </div>
      <div className="flex items-center justify-between w-[200px] mt-2">
        <Link to="/info/agree">
          <button className={cate === "member" ? "blue" : "white"}>
            개인회원
          </button>
        </Link>
        <Link to="/info/agree?cate=clinic">
          <button className={cate === "clinic" ? "blue" : "white"}>
            병원회원
          </button>
        </Link>
      </div>
      {agreeAllData && (
        <TermsTable
          state={agreeAllData}
          onPageClick={onPageClick}
          page={page}
          checkItems={checkItems}
          setCheckItems={setCheckItems}
        />
      )}
    </div>
  );
};

export default AgreePage;
