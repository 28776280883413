import { Column, Entity } from "typeorm";

@Entity("job_opening_view_count", { schema: "dentin" })
export class JobOpeningViewCount {
  @Column("date", { primary: true, name: "proc_date", comment: "처리 일" })
  procDate: string;

  @Column("bigint", { primary: true, name: "job_id", comment: "채용 공고 ID" })
  jobId: string;

  @Column("int", {
    name: "view_count",
    comment: "조회수",
    default: () => "'0'",
  })
  viewCount: number;
}
