import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getHost } from "../..";
import DentinClassTable from "../../components/board/DentinClassTable";
import FileInput from "../../components/form/FileInput";
import Popup from "../../components/Popup";
import { CommonCode } from "../../entities/entities/CommonCode";
import { LectureCourse } from "../../entities/entities/LectureCourse";
import { EntityService } from "../../services/entity.service";

const entityService = new EntityService();
const groupCodeId = 15;

const DentinClassPage = () => {
  const groupCodeId = 15;
  const [writePopup, setWritePopup] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const mainCate = Number(param.get("mainCate")) || undefined;
  const [code, setCode] = useState<CommonCode[] | null>(null);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchCategories();
      setCode(res);
    })();
  }, []);

  return (
    <div className="inner page">
      {writePopup && (
        <WritePopup
          close={() => setWritePopup(false)}
          modify={(writePopup as any)?.course_id ? writePopup : false}
        />
      )}
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <div className="page-tit mr-5">치과인강좌</div>
          <div className="page-sub-tit mr-5">코스 목록</div>
          {code && (
            <>
              <select
                className="w-[125px] mr-5"
                onChange={(e) => {
                  if (!e.target.value) {
                    navigate("/board/dentin-class");
                    return;
                  }
                  page && param.set("page", page.toString());
                  param.set("mainCate", e.target.value);
                  navigate(`/board/dentin-class?${param}`);
                }}
              >
                <option value="">1차 카테고리</option>
                {code
                  .filter((c) => (c as any).groupCodeId === groupCodeId)
                  .map((c) => (
                    <option value={c.codeId}>{c.codeName}</option>
                  ))}
              </select>
              <select
                className="w-[125px] mr-5"
                onChange={(e) => {
                  if (!e.target.value) {
                    navigate(`/board/dentin-class?mainCate=${mainCate}`);
                    return;
                  }
                  page && param.set("page", page.toString());
                  mainCate && param.set("mainCate", mainCate.toString());
                  param.set("subCate", e.target.value);
                  navigate(`/board/dentin-class?${param}`);
                }}
              >
                <option value="">2차 카테고리</option>
                {mainCate &&
                  code
                    .find((c) => (c as any).codeId === mainCate)
                    ?.commonCodeSecondaries.map((c) => (
                      <option value={c.secondaryCodeId}>
                        {c.secondaryCodeName}
                      </option>
                    ))}
              </select>
            </>
          )}
        </div>
        <button className="blue" onClick={() => setWritePopup(true)}>
          등록하기
        </button>
      </div>
      <DentinClassTable setWritePopup={setWritePopup} />
    </div>
  );
};

export default DentinClassPage;

function WritePopup({ close, modify }) {
  const [creator, setCreator] = useState<string>(modify?.creator || "");
  const [title, setTitle] = useState<string>(modify?.title || "");
  const [description, setDescription] = useState<string>(
    modify?.description || ""
  );
  const [mainCate, setMainCate] = useState<number | null>(
    modify?.common_code_id || null
  );
  const [subCate, setSubCate] = useState<number | null>(
    modify?.common_code_secondary_id || null
  );
  const [score, setScore] = useState<number>(modify?.score || "");
  const [priority, setPriority] = useState<number>(modify?.priority || "");
  const [thumbnail, setThumbnail] = useState<any>(
    modify?.image_filename
  );
  const [code, setCode] = useState<CommonCode[] | null>(null);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchCategories();
      setCode(res);
    })();
  }, []);

  const submit = async () => {
    if (!mainCate) {
      alert("1차 카테고리를 입력해주세요.");
      return false;
    }

    if (!subCate) {
      alert("2차 카테고리를 입력해주세요.");
      return false;
    }

    if (!creator) {
      alert("제작자를 입력하세요.");
      return false;
    }

    if (!title) {
      alert("제목을 입력하세요.");
      return false;
    }

    if (!priority) {
      alert("노출순서를 정해주세요.");
      return false;
    }

    try {
      if (modify) {
        await entityService.update(LectureCourse, modify.course_id, {
          creator,
          description,
          title,
          priority: isNaN(+priority) ? 0 : +priority,
          commonCodeId: mainCate?.toString(),
          commonCodeSecondaryId: subCate?.toString(),
          score: isNaN(+score) ? 0 : +score,
          lectureType: 2,
          imageFilename: thumbnail,
          recommendFlag: false,
          startDate: new Date(),
          regDate: new Date(),
        });
      } else {
        await entityService.create(LectureCourse, {
          creator,
          description,
          priority: isNaN(+priority) ? 0 : +priority,
          title,
          commonCodeId: mainCate?.toString(),
          commonCodeSecondaryId: subCate?.toString(),
          score: isNaN(+score) ? 0 : +score,
          lectureType: 2,
          imageFilename: thumbnail,
          recommendFlag: false,
          startDate: new Date(),
          regDate: new Date(),
        });
      }

      return true;
    } catch {
      alert("에러가 발생하였습니다");
    }
  };

  return (
    <Popup
      title="치과인강좌 코스등록"
      submit={async () => {
        try {
          const popupElement = await submit();

          if (popupElement) {
            alert("등록되었습니다");
            close();
            window.location.reload();
          }
        } catch {
          alert("에러가 발생하였습니다");
        }
      }}
      close={close}
      confirmTxt="등록"
    >
      <div className="space-y-5">
        <div className="flex justify-between items-center">
          <div className="w-[15%]">제작자</div>
          <input
            className="text-input"
            value={creator}
            type="text"
            placeholder="텍스트를 입력하세요"
            onChange={(e) => setCreator(e.target.value)}
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">제목</div>
          <input
            className="text-input"
            value={title}
            type="text"
            placeholder="텍스트를 입력하세요"
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">카테고리</div>
          <select
            className="w-[40%] mr-5"
            onChange={(e) => setMainCate(parseInt(e.target.value))}
            value={mainCate?.toString()}
          >
            <option value="">1차 카테고리</option>
            {code &&
              code
                .filter((c) => (c as any).groupCodeId === groupCodeId)
                .map((c) => <option value={c.codeId}>{c.codeName}</option>)}
          </select>
          <select
            className="w-[40%] mr-5"
            onChange={(e) => setSubCate(parseInt(e.target.value))}
            value={subCate?.toString()}
          >
            <option value="">2차 카테고리</option>
            {mainCate &&
              code &&
              code
                .find((c) => (c as any).codeId === mainCate)
                ?.commonCodeSecondaries.map((c) => (
                  <option value={c.secondaryCodeId}>
                    {c.secondaryCodeName}
                  </option>
                ))}
          </select>
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">썸네일</div>
          <div className="w-[85%]">
            <FileInput
              selectFile={async (f) => {
                const res = await axios.post(
                  `${getHost()}/admin/file/upload`,
                  { file: f[0] },
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                setThumbnail(res.data.data.file_name);
              }}
              file={thumbnail}
            />
          </div>
        </div>
        <div className="text-sm text-end text-[#aaa] relative -top-3">
          권장사이즈: 220 x 140px
        </div>
        <div className="flex justify-between items-top">
          <div className="w-[15%]">설명</div>
          <textarea
            className="shadow-custom w-[85%] h-36 rounded-sm px-4 py-2"
            placeholder="텍스트를 입력하세요"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">이수점수</div>
          <input
            className="text-input"
            value={score}
            onChange={(e) => setScore(parseInt(e.target.value))}
            type="number"
            placeholder="0"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">노출순서</div>
          <input
            className="text-input"
            value={priority}
            onChange={(e) => setPriority(parseInt(e.target.value))}
            type="number"
            placeholder="0"
            style={{ width: "85%" }}
          />
        </div>
      </div>
    </Popup>
  );
}
