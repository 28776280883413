import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonCode } from "../../entities/entities/CommonCode";
import { CommonCodeSecondary } from "../../entities/entities/CommonCodeSecondary";
import { LectureCategoryConfig } from "../../entities/entities/LectureCategoryConfig";
import { EntityService } from "../../services/entity.service";
import { cls } from "../../utils/class";

const groupCodeId = 15;
const entityService = new EntityService();

export default function CategoryWrite() {
  const location = useLocation();
  const modify = location.state;
  const [cateName, setCateName] = useState<string>(
    modify?.secondaryCodeName || modify?.codeName || ""
  );
  const [parentCate, setParentCate] = useState<string>(
    modify?.isSecondary ? modify.primaryCodeId : ""
  );
  const [isPublish, setIsPublish] = useState<boolean>(
    modify ? modify?.published : true
  );
  const [code, setCode] = useState<CommonCode[] | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchCategories();
      setCode(res);
    })();
  }, []);

  const submit = async () => {
    try {
      if (modify?.id) {
        if (modify.isSecondary) {
          await entityService.update(
            CommonCodeSecondary,
            modify.secondaryCodeId,
            {
              primaryCodeId: parentCate,
              secondaryCodeName: cateName,
            }
          );
          await entityService.update(LectureCategoryConfig, modify.id, {
            isSecondary: true,
            primaryCodeId: null,
            published: isPublish,
            secondaryCodeId: modify.secondaryCodeId
          });
        } else {
          await entityService.update(CommonCode, modify.codeId, {
            codeName: cateName,
            groupCodeId: groupCodeId.toString(),
          });
          await entityService.update(LectureCategoryConfig, modify.id, {
            isSecondary: false,
            primaryCodeId: modify.codeId,
            secondaryCodeId: null,
            published: isPublish,
          });
        }
        alert("수정되었습니다");
      } else {
        if (parentCate) {
          const res = await entityService.create(CommonCodeSecondary, {
            primaryCodeId: parentCate,
            secondaryCodeName: cateName,
          });
          entityService.create(LectureCategoryConfig, {
            isSecondary: true,
            primaryCodeId: null,
            secondaryCodeId: res.toString(),
            published: isPublish,
          });
        } else {
          const res = await entityService.create(CommonCode, {
            codeName: cateName,
            groupCodeId: groupCodeId.toString(),
          });
          await entityService.create(LectureCategoryConfig, {
            isSecondary: false,
            primaryCodeId: res.toString(),
            secondaryCodeId: null,
            published: isPublish,
          });
        }
        alert("추가되었습니다");
      }
      navigate("/board/category");
    } catch(e) {
      alert("에러가 발생하였거나 카테고리 이름이 중복되었습니다");
    }
  };

  return (
    <div className="inner page">
      <div className="flex">
        <div className="page-sub-tit">카테고리 관리</div>
        <div className="text-4xl mx-3 text-[#666]">&gt;</div>
        <div className="page-sub-tit" style={{ color: "#333" }}>
          등록
        </div>
      </div>
      <div className="flex items-end text-[#333] mt-10">
        <select
          className="w-[250px]"
          onChange={(e) => setParentCate(e.target.value)}
          value={parentCate}
          disabled={modify && !modify.isSecondary}
        >
          <option value="">카테고리를 선택하세요</option>
          { code && (!modify || modify.isSecondary) &&
            code
              .filter((c) => (c as any).groupCodeId === groupCodeId)
              .map((c) => <option value={c.codeId}>{c.codeName}</option>)}
        </select>
        <div className="text-sm ml-5">
          카테고리를 선택하지 않은 경우, 자동으로 3차 카테고리로 등록됩니다.
        </div>
      </div>
      <input
        onChange={(e) => setCateName(e.target.value)}
        value={cateName}
        type="text"
        className="text-input mt-4"
        placeholder="카테고리 명"
        style={{ width: "100%" }}
      />
      <div className="space-y-10 mt-12">
        {/* <CheckboxInput title="목록 조회 권한" />
        <CheckboxInput title="게시글 읽기 권한" /> */}
        <div className="text-[#333] font-semibold text-2xl mb-3">공개여부</div>
        <div className="px-2 space-x-2 flex flex-wrap items-center">
          <div className="mb-4">
            <label
              onClick={() => setIsPublish(true)}
              className={cls(
                isPublish
                  ? "border-main text-main"
                  : "border-[#ddd] text-[#666]",
                "border-[1px] p-2 rounded-md text-base cursor-pointer"
              )}
            >
              공개
            </label>
          </div>
          <div className="mb-4">
            <label
              onClick={() => setIsPublish(false)}
              className={cls(
                !isPublish
                  ? "border-main text-main"
                  : "border-[#ddd] text-[#666]",
                "border-[1px] p-2 rounded-md text-base cursor-pointer"
              )}
            >
              비공개
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end mt-16">
        <button onClick={() => navigate(-1)}>취소</button>
        <button className="white" onClick={submit}>
          {modify?.id ? "수정" : "등록"}
        </button>
      </div>
    </div>
  );
}
