import {
  Column,
  Entity,
  Index,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { JobAction } from "./JobAction";
import { ClinicMember } from "./ClinicMember";
import { MemberWish } from "./MemberWish";
import { Occupation } from "./Occupation";

@Index("fk_job_opening_member_id", ["memberId"], {})
@Index(
  "idx_job_opening_composite_1",
  ["closingDate", "publicFlag", "dentistFlag"],
  {}
)
@Index("idx_job_opening_intern_flag", ["internFlag"], {})
@Index("idx_job_opening_upd_date", ["updDate"], {})
@Index("idx_job_opening_search_text", ["searchText"], { fulltext: true })
@Entity("job_opening", { schema: "dentin" })
export class JobOpening {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "job_id",
    comment: "채용 공고 ID",
  })
  jobId: string;

  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "병원 회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("varchar", {
    name: "logo_image_filename",
    nullable: true,
    comment: "병원 로고 이미지 파일명",
    length: 256,
  })
  logoImageFilename: string | null;

  @Column("varchar", { name: "clinic_name", comment: "병원명", length: 64 })
  clinicName: string;

  @Column("bit", {
    name: "intern_flag",
    comment: "실습 채용 공고 여부",
    default: () => "'b'0''",
  })
  internFlag: boolean;

  @Column("varchar", { name: "address", comment: "병원 주소", length: 256 })
  address: string;

  @Column("varchar", {
    name: "extra_address",
    nullable: true,
    comment: "추가 주소",
    length: 256,
  })
  extraAddress: string | null;

  @Column("varchar", {
    name: "homepage_url",
    nullable: true,
    comment: "병원 홈페이지 URL",
    length: 256,
  })
  homepageUrl: string | null;

  @Column("varchar", {
    name: "phone_number",
    nullable: true,
    comment: "병원 연락처",
    length: 32,
  })
  phoneNumber: string | null;

  @Column("longtext", {
    name: "image_info",
    nullable: true,
    comment: "병원 이미지 정보 (이미지 파일 list)",
  })
  imageInfo: string | null;

  @Column("varchar", { name: "title", comment: "채용 공고 제목", length: 128 })
  title: string;

  @Column("int", {
    name: "employee_cnt",
    nullable: true,
    comment: "구성원 현황",
  })
  employeeCnt: number | null;

  @Column("text", {
    name: "introduction",
    nullable: true,
    comment: "병원 소개",
  })
  introduction: string | null;

  @Column("longtext", { name: "occupation_info", comment: "직종 정보" })
  occupationInfo: string;

  @Column("longtext", {
    name: "work_info",
    comment: "업무 정보 (채용 업무, 근무 형태)",
  })
  workInfo: string;

  @Column("varchar", {
    name: "last_education_type",
    comment: "최종 학력",
    length: 64,
  })
  lastEducationType: string;

  @Column("int", {
    name: "min_work_experience",
    nullable: true,
    comment: "최소 경력",
  })
  minWorkExperience: number | null;

  @Column("int", {
    name: "max_work_experience",
    nullable: true,
    comment: "최대 경력",
  })
  maxWorkExperience: number | null;

  @Column("longtext", { name: "salary_info", comment: "연봉 정보" })
  salaryInfo: string;

  @Column("varchar", {
    name: "work_day_type",
    nullable: true,
    comment: "근무 요일 유형",
    length: 64,
  })
  workDayType: string | null;

  @Column("varchar", {
    name: "work_time",
    nullable: true,
    comment: "근무 시간 정보",
    length: 64,
  })
  workTime: string | null;

  @Column("longtext", {
    name: "benefit_info",
    nullable: true,
    comment: "복리 후생 정보",
  })
  benefitInfo: string | null;

  @Column("longtext", {
    name: "submission_info",
    nullable: true,
    comment: "제출 서류 정보",
  })
  submissionInfo: string | null;

  @Column("longtext", {
    name: "screening_info",
    nullable: true,
    comment: "전형 정보",
  })
  screeningInfo: string | null;

  @Column("date", { name: "closing_date", comment: "마감일" })
  closingDate: string;

  @Column("varchar", {
    name: "contact_email",
    nullable: true,
    comment: "담당자 이메일",
    length: 64,
  })
  contactEmail: string | null;

  @Column("varchar", {
    name: "contact_phone_number",
    nullable: true,
    comment: "담당자 연락처 ",
    length: 32,
  })
  contactPhoneNumber: string | null;

  @Column("bit", { name: "public_flag", comment: "공개 여부" })
  publicFlag: boolean;

  @Column("tinyint", {
    name: "dentist_flag",
    comment: "치과의사 채용 여부 (1: 치과의사, 2: 스탭, 3: 둘 다)",
  })
  dentistFlag: number;

  @Column("longtext", {
    name: "notification_info",
    nullable: true,
    comment: "알림 수신 방법 정보",
  })
  notificationInfo: string | null;

  @Column("text", {
    name: "details",
    nullable: true,
    comment: "상세 채용 정보",
  })
  details: string | null;

  @Column("int", {
    name: "view_count",
    comment: "조회수",
    default: () => "'0'",
  })
  viewCount: number;

  @Column("text", {
    name: "search_text",
    nullable: true,
    comment: "검색 문자열",
  })
  searchText: string | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("int", {
    name: "hiring_count",
    nullable: true,
    comment: "구인 인원수",
    default: () => "'0'",
  })
  hiringCount: number | null;

  @OneToMany(() => JobAction, (jobAction) => jobAction.job)
  jobActions: JobAction[];

  @ManyToOne(() => ClinicMember, (clinicMember) => clinicMember.jobOpenings, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: ClinicMember;

  @OneToMany(() => MemberWish, (memberWish) => memberWish.job)
  memberWishes: MemberWish[];

  @ManyToMany(() => Occupation, (occupation) => occupation.jobOpenings)
  @JoinTable({
    name: "job_opening_occupation",
    joinColumns: [{ name: "job_id", referencedColumnName: "jobId" }],
    inverseJoinColumns: [
      { name: "occupation_id", referencedColumnName: "occupationId" },
    ],
    schema: "dentin",
  })
  occupations: Occupation[];
}
