import axios from "axios";
import { getHost } from "../..";

export const login = async (id: string, password: string) => {
  const token = await __fetchAdminToken(id, password);

  return token;
};

const __fetchAdminToken: (
  id: string,
  password: string
) => Promise<string> = async (id: string, password: string) => {
  const loginUrl = getHost() + "/admin/login";

  const response = await axios.post(
    loginUrl,
    {
      admin_id: id,
      password: password,
    },
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );

  const token = response.headers.authorization;

  if (!token || token === "" || token === undefined) {
    throw new Error("Admin 계정이 잘못되었습니다.");
  } else {
    sessionStorage.setItem("token", token);
    const defaultHeader = await __getDefaultHeader();

    axios.interceptors.request.use(async (request) => {
      request.headers = {
        ...defaultHeader,
        ...request.headers,
      };

      return request;
    });
  }

  return token;
};

export const getToken = async () => {
  const tokenFromStorage = sessionStorage.getItem("token");

  if (
    !tokenFromStorage ||
    tokenFromStorage === "" ||
    tokenFromStorage === undefined ||
    tokenFromStorage === null
  ) {
    const newToken = await fetchTokenThenSave();

    return newToken;
  } else {
    return tokenFromStorage;
  }
};

export const __getDefaultHeader = async () => {
  const token = await getToken();

  const header = {
    "Content-Type": "text/plain",
    Authorization: `Bearer ${token}`,
  };

  return header;
};

export const fetchTokenThenSave = async () => {
  const id = sessionStorage.getItem("memberId");
  const password = sessionStorage.getItem("memberPassword");

  if (!id || !password) {
    throw new Error("다시 로그인해주세요.");
  }

  const newToken = await __fetchAdminToken(id, password);

  sessionStorage.setItem("token", newToken);

  return newToken;
};
