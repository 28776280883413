import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EducationFaq } from "../../entities/entities/EducationFaq";
import { Faq } from "../../entities/entities/Faq";
import { EntityService } from "../../services/entity.service";
import htmlToDraft from "html-to-draftjs";
import axios from "axios";
import { getHost } from "../..";

const FaqWrite = () => {
  const { state } = useLocation();
  const [type, setType] = useState(state?.type ?? "");
  const [question, setQuestion] = useState(state?.question ?? "");
  const [answer, setAnswer] = useState<string>(state?.answer ?? "");
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState: EditorState) => {
    // editorState에 값 설정
    setEditorState(editorState);
  };

  const submit = async () => {
    if (type.length === 0) {
      alert("카테고리를 선택해주세요");
      return;
    }

    const entityService = new EntityService();

    const faq = {
      question: question,
      answer: answer,
      reg_date: new Date(),
    };

    if (state) {
      if (type === "recruit") {
        try {
          await entityService.update(Faq, state.faqId, faq);
          navigate(`/communication/faq?type=${type}`);
        } catch (e) {
          console.log(e);
        }
      }

      if (type === "education") {
        try {
          await entityService.update(EducationFaq, state.faqId, faq);
          navigate(`/communication/faq?type=${type}`);
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      if (type === "recruit") {
        try {
          await entityService.create(Faq, faq);
          navigate(`/communication/faq?type=${type}`);
        } catch (e) {
          console.log(e);
        }
      }

      if (type === "education") {
        try {
          await entityService.create(EducationFaq, faq);
          navigate(`/communication/faq?type=${type}`);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(state?.answer || answer);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [state, answer]);

  return (
    <div className="inner page">
      <div className="page-tit">FAQ 작성</div>
      <div className="space-y-4 mt-10">
        <select
          className="w-[300px]"
          onChange={(e) => setType(e.target.value)}
          required
        >
          <option value="" disabled selected={type === ""}>
            카테고리 선택
          </option>
          <option value="recruit" selected={type === "recruit"}>
            구인구직
          </option>
          <option value="education" selected={type === "education"}>
            온라인교육
          </option>
        </select>
        <input
          type="text"
          className="text-input"
          style={{ width: "100%" }}
          onChange={(e) => setQuestion(e.target.value)}
          defaultValue={state?.question}
        />
        <Editor
          editorStyle={{
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.24), 0px 0px 1px rgba(0, 0, 0, 0.06)",
            borderRadius: "3px",
            minHeight: "580px",
            padding: "0 16px",
          }}
          localization={{
            locale: "ko",
          }}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "colorPicker",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "emoji",
              "image",
            ],
            image: {
              uploadCallback: (file: File) => {
                return new Promise((resolve, reject) => {
                  axios
                    .post(
                      `${getHost()}/admin/file/upload`,
                      { file },
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {
                      resolve({
                        data: {
                          link:
                            `${getHost()}/` +
                            res.data.data.file_name,
                        },
                      });
                    });
                });
              },
              alt: { present: true, mandatory: false },
            },
          }}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          onBlur={() => {
            const editorToHtml = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            setAnswer(editorToHtml);
          }}
        />
      </div>
      <div className="flex items-center justify-center mt-16 space-x-2">
        <Link to="/communication/faq">
          <button>취소</button>
        </Link>
        <button className="white" onClick={submit}>
          {state ? "수정" : "등록"}
        </button>
      </div>
    </div>
  );
};

export default FaqWrite;
