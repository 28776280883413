import HomeNow from "../../components/HomeNow";
import Daily from "../../components/home/Daily";
import { useState, useEffect } from "react";
import { LandingPageData } from "../../interfaces/landing-page-data.interface";
import { EntityService } from "../../services/entity.service";
import { Link } from "react-router-dom";
import NewVideo from "../../components/NewVideo";

const DailyPage = () => {
  const entityService = new EntityService();

  const [landingPageData, setLandingPageData] = useState<LandingPageData>();
  const [dates, setDates] = useState(new Date());

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchLandingPageDataByDate(dates);

      setLandingPageData(res);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  return (
    <div className="max-w-[1050px] m-auto pb-28">
      <Daily
        landingPageData={landingPageData}
        dates={dates}
        setDates={setDates}
      />
      <div className="px-5 w-[1050px]">
        <div className="flex items-start justify-between mb-7">
          <HomeNow title="실시간 채용공고" data={landingPageData?.recruits} />
          <HomeNow
            title="실시간 인재정보"
            data={landingPageData?.humanResources}
          />
        </div>
        <div className="flex items-start justify-between">
          <HomeNow
            title="실시간 실습치과공고"
            data={landingPageData?.internRecruits}
          />
          <NewVideo data={landingPageData?.latestVideoList} />
        </div>
      </div>
      <div className="flex items-center justify-between mt-10 w-[1050px] px-8">
        <div className="space-y-4">
          <Link to="/statistics/visit">
            <div className="flex items-center justify-between w-[410px] shadow-custom px-8 py-7 rounded-md">
              <div className="text-[#666] text-[35px] font-semibold">
                방문분석
              </div>
              <img src="/visit.svg" alt="visit" />
            </div>
          </Link>
          <Link to="/statistics/member">
            <div className="flex items-center justify-between w-[410px] shadow-custom px-8 py-7 rounded-md">
              <div className="text-[#666] text-[35px] font-semibold">
                회원분석
              </div>
              <img src="/member.svg" alt="member" />
            </div>
          </Link>
          <Link to="/statistics/ranking">
            <div className="flex items-center justify-between w-[410px] shadow-custom px-8 py-7 rounded-md">
              <div className="text-[#666] text-[35px] font-semibold">순위</div>
              <img src="/chart.svg" alt="chart" />
            </div>
          </Link>
        </div>
        <div>
          <img src="/statistics.svg" alt="static" />
        </div>
      </div>
    </div>
  );
};

export default DailyPage;
