import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EntityService } from "../services/entity.service";
import { Banner } from "../entities/entities/Banner";
import { toYYYYMMDD } from "../services/utils/repository-util.service";
import Popup from "../components/Popup";
import { BANNER_TYPES } from "../constants";
import { getHost } from "..";

const entityService = new EntityService();

const BannerPage = () => {
  const navigate = useNavigate();
  const [deletePopup, setDeletePopup] = useState<Banner | null>(null);
  const [bannerList, setBannerList] = useState<Banner[] | null>(null);
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchBanners();
      setBannerList(res);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, navigate]);

  if (!bannerList) return <></>;
  return (
    <div className="inner page">
      <div className="flex items-end justify-between mb-7">
        <div className="page-tit">배너관리</div>
        <Link to="/bannerwrite">
          <button className="blue">등록하기</button>
        </Link>
      </div>
      <div className="flex items-center justify-evenly w-[1200px] bg-main text-white py-[6px] pl-3 text-base font-semibold">
        <div className="w-[5%]">번호</div>
        <div className="w-[20%]">위치</div>
        <div className="w-[25%]">이미지</div>
        <div className="w-[20%]">연결링크</div>
        <div className="w-[14%]">등록/수정일</div>
        <div className="w-[8%] flex items-center justify-center">수정</div>
        <div className="w-[8%] flex items-center justify-center">삭제</div>
      </div>
      {bannerList.map((banner, idx) => (
        <div className="flex items-center justify-evenly w-[1200px] py-6 border-b-[1px] border-[#999] pl-3 text-[#666] font-semibold text-base">
          <div className="w-[5%]">{idx + 1}</div>
          <div className="w-[20%]">
            {BANNER_TYPES.find((o) => o.value === banner.bannerType)?.title}
          </div>
          <div className="w-[25%]">
            <img
              src={`${getHost()}/${banner.imageFilename}`}
              className="w-[80%]"
              alt="img"
            />
          </div>
          <div className="w-[20%] break-words">{banner.bannerUrl}</div>
          <div className="w-[14%]">
            {banner.regDate ? toYYYYMMDD(new Date(banner.regDate)) : ""}
          </div>
          <button
            className="w-[8%] flex items-center justify-center dark"
            onClick={() => navigate("/bannerwrite", { state: banner })}
          >
            수정
          </button>
          <button
            className="w-[8%] flex items-center justify-center"
            onClick={() => setDeletePopup(banner)}
          >
            삭제
          </button>
        </div>
      ))}
      {deletePopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.delete(Banner, deletePopup.bannerId);
              alert("삭제되었습니다");
              setDeletePopup(null);
              window.location.reload();
            } catch {
              alert("에러가 발생하였습니다");
            }
          }}
          close={() => setDeletePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
};

export default BannerPage;
