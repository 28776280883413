import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonCode } from "../../entities/entities/CommonCode";
import { LectureCourse } from "../../entities/entities/LectureCourse";
import { EntityService } from "../../services/entity.service";
import Popup from "../Popup";

const entityService = new EntityService();

const DentinClassTable = ({ setWritePopup }) => {
  const [dentInClassList, setDentInClassList] = useState<
    [LectureCourse[], number] | null
  >(null);
  const [deletePopup, setDeletePopup] = useState<LectureCourse | null>(null);
  const lectureCourseType = 2;
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const mainCate = Number(param.get("mainCate")) || undefined;
  const subCate = Number(param.get("subCate")) || undefined;
  const [code, setCode] = useState<CommonCode[] | null>(null);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchCategories();
      setCode(res);
    })();
  }, []);

  const mainCateId = (item) =>
    code && code.find((c) => c.codeId === (item as any).common_code_id);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    param.set("page", selected);
    mainCate && param.set("mainCate", mainCate.toString());
    subCate && param.set("subCate", subCate.toString());
    navigate(`/board/dentin-class?${param}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    (async () => {
      const res = await entityService.fecthLectureCourses(
        lectureCourseType,
        page,
        20,
        mainCate,
        subCate
      );
      setDentInClassList(res);
    })();
  }, [page, mainCate, subCate]);

  if (!dentInClassList) return <></>;
  return (
    <div className="table">
      <div className="row header">
        <div className="w-1/12 justify-center">노출순서</div>
        <div className="w-1/12 justify-center">제작자</div>
        <div className="w-1/12 justify-center">카테고리1</div>
        <div className="w-1/12 justify-center">카테고리2</div>
        <div className="w-3/12">제목</div>
        <div className="w-1/12 justify-center">이수점수</div>
        <div className="w-1/12 justify-center">이수자 수</div>
        <div className="w-1/12 justify-center">수강자 목록</div>
        <div className="w-1/12 justify-center">수정</div>
        <div className="w-1/12 justify-center">삭제</div>
      </div>
      {dentInClassList[0].map((item, idx) => (
        <div className="row" key={idx}>
          <div className="w-1/12 justify-center">{item.priority}</div>
          <div className="w-1/12 justify-center">{item.creator}</div>
          <div className="w-1/12 justify-center">
            {mainCateId(item)?.codeName}
          </div>
          <div className="w-1/12 justify-center">
            {
              mainCateId(item)?.commonCodeSecondaries?.find(
                (c) =>
                  c.secondaryCodeId === (item as any).common_code_secondary_id
              )?.secondaryCodeName
            }
          </div>
          <div
            className="w-3/12 cursor-pointer hover:text-main"
            onClick={() =>
              navigate(`/board/dentin-class/${(item as any).course_id}`, {
                state: (item as any).title,
              })
            }
          >
            {item.title}
          </div>
          <div className="w-1/12 justify-center">{item.score}점</div>
          <div className="w-1/12 justify-center">
            {(item as any).finish_count}명
          </div>
          <div className="w-1/12 justify-center">
            <i
              className="ii ii-import text-main text-lg cursor-pointer"
              onClick={async () => {
                const list = await entityService.fetchLectureCourseStudentList(
                  parseInt((item as any).course_id)
                );

                await entityService.downloadJsonArrayToCSV(item.title, list);
              }}
            ></i>
          </div>
          <div className="w-1/12 justify-center">
            <button className="dark" onClick={() => setWritePopup(item)}>
              수정
            </button>
          </div>
          <div className="w-1/12 justify-center">
            <button onClick={() => setDeletePopup(item)}>삭제</button>
          </div>
        </div>
      ))}
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={dentInClassList[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
      {deletePopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteLectureCourse(
                (deletePopup as any).course_id
              );
              alert("삭제되었습니다");
              setDeletePopup(null);
              window.location.reload();
            } catch {
              alert("에러가 발생하였습니다");
            }
          }}
          close={() => setDeletePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
};

export default DentinClassTable;
