import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Admin } from "../../entities/entities/Admin";
import { EntityService } from "../../services/entity.service";

const AdminRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.item;
  const inputArr = ['슈퍼관리자', '시스템관리자','교육관리자', '민원 관리자','글쓰기 관리자', '배너 관리자']
  const [name, setName] = useState<string | undefined>(data?.name ?? "");
  const [id, setId] = useState<string>(data?.adminId ?? "");
  const [password, setPassword] = useState<string | undefined>(
    data?.password ?? ""
  );
  const [email, setEmail] = useState<string | undefined>(data?.email ?? "");
  const [isId, setIsId] = useState<boolean>(data ? true : false);
  const [isPassword, setIsPassword] = useState<boolean>(data ? true : false);
  const [isEmail, setIsEmail] = useState<boolean>(data ? true : false);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    data?.phoneNumber ?? ""
  );
  const [roles, setRoles] = useState<Array<string>>(data?data.role.split(','):[])
  const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const idRegex = /^([0-9a-z_]){4,20}$/;
    const idCurrent = e.target.value;
    setId(idCurrent);

    !idRegex.test(idCurrent) ? setIsId(false) : setIsId(true);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;
    const passwordCurrent = e.target.value;
    setPassword(passwordCurrent);

    !passwordRegex.test(passwordCurrent)
      ? setIsPassword(false)
      : setIsPassword(true);
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailRegex =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const emailCurrent = e.target.value;
    setEmail(emailCurrent);

    !emailRegex.test(emailCurrent) ? setIsEmail(false) : setIsEmail(true);
  };
  const onChangeInput = (e) => {
    let targetRoles = e.target.getAttribute('data-roles')
    if(e.target.checked) {
      setRoles([...roles, targetRoles]);
    } else {
      setRoles(roles.filter((e) => { return e !== targetRoles }));
    }
  }
  const submit = async () => {
    if (id === '' || !id) {
      alert('아이디를 입력해주세요.');
    } else if (name === '' || !name) {
      alert('이름을 입력해주세요.');
    } else if (email === '' || !email) {
      alert('이메일을 입력해주세요.');
    } else if (password === '' || !password) {
      alert('비밀번호를 입력해주세요.');
    } else if (phoneNumber === '' || !phoneNumber) {
      alert('휴대폰 번호를 입력해주세요.');
    } else {

      const entityService = new EntityService();
      const admin = {
        name,
        admin_id: id,
        email,
        password,
        phoneNumber,
        reg_admin_id: id,
        role:roles.toString()
      };

      if (data) {
        try {
          await entityService.update(Admin, id, admin);
          password && await entityService.updatePassword(id, password as string);
          navigate("/info/admin");
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          await entityService.create(Admin, admin);
          await entityService.updatePassword(id, password as string);
          navigate("/info/admin");
        } catch (e) {
          console.log(e);
        }
      }
    }

  };

  return (
    <div className="inner px-4">
      <div className="page-tit">
        <span className="text-[#999]">관리자 계정 목록 〉</span> 관리자 계정
        등록/수정
      </div>
      <div className="border-[1px] border-[#999] rounded-md p-[1px] mt-6">
        <div className="flex items-center space-x-6 border-b border-[#999]">
          <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
            아이디
          </div>
          {data ? (
            <input
              type="text"
              disabled={true}
              defaultValue={data?.adminId ?? ""}
              name="adminId"
              onChange={onChangeId}
            />
          ) : (
            <input
              type="text"
              placeholder="4~20자의 영문, 숫자와 특수문자(_)만 사용 가능"
              className="text-input"
              defaultValue={data?.adminId ?? ""}
              name="adminId"
              onChange={onChangeId}
            />
          )}
        </div>
        <div className="flex items-center space-x-6 border-b border-[#999]">
          <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
            이름
          </div>
          <input
            type="text"
            placeholder="이름을 입력하세요"
            className="text-input"
            defaultValue={data?.name ?? ""}
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex items-center space-x-6 border-b border-[#999]">
          <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
            비밀번호
          </div>
            <input
              type="password"
              placeholder="8~16자리 영문 대소문자, 숫자, 특수문자"
              className="text-input"
              name="password"
              onChange={onChangePassword}
            />
          
        </div>
        <div className="flex items-center space-x-6 border-b border-[#999]">
          <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
            전화번호
          </div>
          <input
            type="text"
            placeholder="전화번호를 입력하세요"
            className="text-input"
            defaultValue={data?.phoneNumber ?? ""}
            name="password"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="flex items-center space-x-6">
          <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
            이메일
          </div>
          <input
            type="text"
            placeholder="이메일 주소를 입력하세요"
            className="text-input"
            defaultValue={data?.email ?? ""}
            name="email"
            onChange={onChangeEmail}
          />
        </div>
        <div className="flex items-center space-x-6">
          <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
            권한
          </div>
          <ul className='flex'>
            {inputArr.map((e,i)=>{
              return (
                <>
                  <li className='space-x-1 ml-3'>
                    <label htmlFor="superAdmin">
                      {e}
                    </label>
                    <input
                      id='superAdmin'
                      type="checkbox"
                      className="fa-check"
                      name='superAdmin'
                      data-roles={i}
                      disabled={i==0}
                      onChange={onChangeInput}
                      checked={roles.indexOf(i+'') != -1}
                    />
                  </li>
                </>
                )
            })}
          </ul>

        </div>
      </div>
      <div className="flex items-center justify-center mt-9 space-x-2">
        <Link to="/info/admin">
          <button>취소</button>
        </Link>
        <button
          className="white disabled:bg-[#ddd] disabled:text-[#EDEDED] disabled:border-[#ddd]"
          onClick={submit}
        >
          {data ? "수정" : "등록"}
        </button>
      </div>
    </div>
  );
};

export default AdminRegister;
