import { Column, Entity, Index, PrimaryGeneratedColumn } from "typeorm";

@Index("idx_notice_composite_date", ["updDate"], {})
@Index("idx_notice_composite_1", ["title", "contents"], { fulltext: true })
@Entity("reference", { schema: "dentin" })
export class Reference {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "reference_id",
    comment: "자료실 ID",
  })
  referenceId: string;

  @Column("varchar", { name: "title", comment: "자료실 제목", length: 32 })
  title: string;

  @Column("text", { name: "contents", nullable: true, comment: "자료실 내용" })
  contents: string | null;

  @Column("int", { name: "view_count", comment: "조회 수" })
  viewCount: number;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("bit", {
    name: "intern_flag",
    nullable: true,
    comment: "실습 여부",
    default: () => "'b'0''",
  })
  internFlag: boolean | null;

  @Column("varchar", {
    name: "file_download_link",
    nullable: true,
    comment: "첨부 파일 다운로드 링크",
    length: 2048,
  })
  fileDownloadLink: string | null;

  @Column("varchar", {
    name: "file_download_filename",
    nullable: true,
    comment: "첨부 파일 이름",
    length: 2048,
  })
  fileDownloadFilename: string | null;
}
