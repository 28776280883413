import {
  Column,
  Entity,
  Index,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { MemberPolicyAgree } from "./MemberPolicyAgree";
import { ClinicMemberPolicyAgree } from "./ClinicMemberPolicyAgree";

@Index("idx_policy_composite_1", ["type", "category"], {})
@Entity("policy", { schema: "dentin" })
export class Policy {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "policy_id",
    comment: "정책 ID",
  })
  policyId: string;

  @Column("tinyint", {
    name: "type",
    comment: "정책 유형 (1. term, 2. privacy)",
  })
  type: number;

  @Column("tinyint", {
    name: "category",
    comment: "정책 유형 (1. 개인회원, 2. 병원 회원, 3. 공통)",
  })
  category: number;

  @Column("bit", { name: "require_flag", comment: "필수 정책 여부" })
  requireFlag: boolean;

  @Column("varchar", { name: "title", comment: "정책 제목", length: 128 })
  title: string;

  @Column("longtext", { name: "contents", comment: "정책 내용" })
  contents: string;

  @Column("datetime", {
    name: "eff_date",
    comment: "정책 시행 일시",
    default: () => "CURRENT_TIMESTAMP",
  })
  effDate: Date;

  @Column("datetime", {
    name: "reg_date",
    comment: "등록 일시",
    default: () => "CURRENT_TIMESTAMP",
  })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("int", { name: "revision", nullable: true })
  revision: number | null;

  @OneToMany(
    () => MemberPolicyAgree,
    (memberPolicyAgree) => memberPolicyAgree.policy
  )
  memberPolicyAgrees: MemberPolicyAgree[];

  @OneToMany(
    () => ClinicMemberPolicyAgree,
    (clinicMemberPolicyAgree) => clinicMemberPolicyAgree.policy
  )
  clinicMemberPolicyAgrees: ClinicMemberPolicyAgree[];
}
