import {useEffect, useRef, useState} from "react";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
// Toast UI Editor
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import { Notice } from "../../entities/entities/Notice";
import { EntityService } from "../../services/entity.service";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Reference } from "../../entities/entities/Reference";
import { EducationNotice } from "../../entities/entities/EducationNotice";
import { getHost } from "../..";
import { LexicalEditor } from "lexical";
import DentInLexcialEditor from "../editor/DentInLexicalEditor";

interface FileModel {
  fileName: string;
  uploadFileName: string;
}

const EditorSelectForm = ({ title }: { title: string }) => {
  const { state } = useLocation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [file, setFile] = useState<FileModel[]>([]);
  const [content, setContent] = useState<string>("");
  const [noticeDataTitle, setNoticeDataTitle] = useState<string>("");
  const [noticeOption, setNoticeOption] = useState<string>(
    state?.noticeType ?? "0"
  );
  const [notice, setNotice] = useState<number>(0);
  const [data, setData] = useState<boolean>(false);
  const [textContent, setTextContent] = useState<string>(state?.contents ?? "");
  const navigate = useNavigate();

  const editorRef = useRef<any>();

  const onChange = () => {
    const data = editorRef.current.getInstance().getHTML();
    setContent(data)
    console.log(data);
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(state?.contents || content);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [state, content]);

  useEffect(() => {
    if (state?.fileDownloadFilename) {

      let nameArr = state?.fileDownloadFilename.split(',')
      let initialArr:Array<FileModel> = []
      nameArr.forEach((val) => {
        initialArr.push({
          fileName: val,
          uploadFileName: val,
        })
      })
      setFile(initialArr)
    }
  }, [state?.fileDownloadFilename]);

  //require flag,title 추가
  //upd_date:null 이면 게시됨
  const addFile = (e) => {
    if(file.length == 10) {
      alert('첨부파일은 10개를 초과할 수 없습니다.')
      return;
    }
    const targetFile = e.target.files[0];

    if(targetFile.name.includes(',')){
      alert('첨부파일에 ","가 포함되어 있습니다')
      return;
    }
    targetFile &&
      axios
        .post(
          `${getHost()}/admin/file/upload`,
          { file:targetFile },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setFile([{
            fileName: res.data.data.file_name,
            uploadFileName: res.data.data.upload_file_name,
          },...file]);

        })
        .catch((err) => alert("파일을 다시 첨부해주세요"));
  };
  const submit = async () => {
    if (title === "공지사항 등록" && noticeOption === "0") {
      alert("카테고리를 지정해주세요");

      return;
    }

    const entityService = new EntityService();
    const type = Number(noticeOption);
  //return;
    // @ts-ignore
    let link = file.map(x => x.fileName).join(',');
    let name = file.map(x => x.uploadFileName).join(',');

    const noticeData = {
      noticeType: type,
      title: noticeDataTitle,
      viewCount: state?.viewCount ? state.viewCount : 0,
      startDate: new Date(),
      regDate: new Date(),
      contents: content,
      focus: notice,
      fileDownloadFilename: name,
      fileDownloadLink: link,
    };
    const boardData = {
      contents: textContent,
      fileDownloadFilename: name,
      fileDownloadLink: link,
      internFlag: data,
      regDate: new Date(),
      title: noticeDataTitle,
      updDate: null,
      viewCount: state?.viewCount ? state.viewCount : 0,
    };
    if (title === "자료실 등록") {
      if (state) {
        entityService
          .update(Reference, state.referenceId, boardData)
          .then(() => navigate(-1))
          .catch((e) => console.log(e));
      } else {
        entityService
          .create(Reference, boardData)
          .then(() => navigate(-1))
          .catch((e) => console.log(e));
      }
    } else {
      if (state) {
        const ensuredTitle =
          noticeData.title === "" ? state.title : noticeData.title;
        const ensuredContents =
          noticeData.contents === "" ? state.contents : noticeData.contents;
        if (type === 3) {
          entityService
            .update(EducationNotice, state.noticeId, {
              ...state,
              fileDownloadFilename: name,
              fileDownloadLink: link,
              title: ensuredTitle,
              contents: ensuredContents,
              noticeType: type,
            })
            .then(() => navigate(-1))
            .catch((e) => console.log(e));
        } else {
          entityService
            .update(Notice, state.noticeId, {
              ...state,
              fileDownloadFilename: name,
              fileDownloadLink: link,
              title: ensuredTitle,
              contents: ensuredContents,
              noticeType: type,
            })
            .then(() => navigate(-1))
            .catch((e) => console.log(e));
        }
      } else {
        const NoticeEntity = type === 3 ? EducationNotice : Notice;
        entityService
          .create(NoticeEntity, noticeData)
          .then(() => navigate(-1))
          .catch((e) => console.log(e));
      }
    }
  };

  return (
    <div>
      <div className="inner page px-4">
        <div className="page-tit mb-10">
          {title === "공지사항 등록" && (
            <span className="text-[#999]">공지사항 〉</span>
          )}
          {title === "공지사항 등록" ? "등록" : title}
        </div>
        <div className="space-y-4">
          <div className="flex items-cend space-x-6">
            {title === "공지사항 등록" && (
              <select
                className="w-52"
                onChange={(e) => setNoticeOption(e.target.value)}
                value={noticeOption}
                required
              >
                <option value="0">카테고리</option>
                <option value="1">공지사항</option>
                <option value="2">이벤트</option>
                {/* <option value="3">온라인교육</option> */}
                <option value="4">실습치과</option>
              </select>
            )}
            {title === "공지사항 등록" && (
              <div className="w-4/12 flex items-center">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="check"
                  onChange={(e) => setNotice(e.target.checked ? 1 : 0)}
                  checked={state?.focus === 1}
                />
                <label htmlFor="check"></label>
                공지
              </div>
            )}
            {title === "자료실 등록" && (
              <div className="w-4/12 flex items-center">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="check"
                  onChange={(e) => setData(e.target.checked)}
                />
                <label htmlFor="check"></label>
                실습치과 노출 여부
              </div>
            )}
          </div>

          <div>
            <input
              type="text"
              className="text-input"
              style={{ width: "100%" }}
              placeholder="제목을 입력하세요."
              onChange={(e) => setNoticeDataTitle(e.target.value)}
              defaultValue={state?.title ?? ""}
            />
          </div>
          {title === "공지사항 등록" && (
            <Editor
              ref={editorRef}
              hooks={
                {
                  async addImageBlobHook(blob, callback) { // 이미지 업로드 로직 커스텀
                    try {
                      axios
                        .post(
                          `${getHost()}/admin/file/upload`,
                          { file:blob },
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        )
                        .then((res) => {
                          const imageUrl = `${getHost()}/` +res.data.data.file_name;
                          callback(imageUrl, '');
                        });

                    } catch (error) {
                      console.error('업로드 실패 : ', error);
                    }
                  }
                }
              }
              language="ko-KR"
              height="600px"
              initialEditType="wysiwyg"
              initialValue={state?.contents}
              onChange={onChange}
            />
            // <DentInLexcialEditor />
          )}
          {title === "자료실 등록" && (
            <textarea
              className="shadow-custom w-full rounded-md min-h-[450px] px-3 py-3"
              onChange={(e) => setTextContent(e.target.value)}
              defaultValue={state?.contents ?? ""}
            />
          )}
        </div>

        {
          file.map((v,i)=>{
            return(
              <>
                <div className="mt-8 flex items-center space-x-4 text-[#666]">
                  <i className="ii-document-with-lines-outline text-xl"></i>
                  <div className="text-base font-semibold w-[320px] truncate">
                    {v.fileName ??
                    "파일을 선택해주세요.(.pdf, .hwp, .doc, .docx)"}
                  </div>
                  <div
                    className="text-sm text-[#666] font-medium bg-[#F5F5F5] rounded-full px-3 py-[1px] cursor-pointer"
                    onClick={() => setFile(file.filter(item => item.fileName !== v.fileName))}
                  >
                    삭제
                  </div>
                </div>
              </>
              )
          })
        }
        <div className="mt-8 mb-10">
          <label className="bg-[#A9A9A9] text-white rounded-md flex items-center justify-between px-3 w-28 h-12 cursor-pointer">
            <input
              type="file"
              className="hidden"
              accept=".pdf, .hwp, .doc, .docx"
              onChange={addFile}
            />
            <div className="text-base text-white">{file.length==0?'첨부파일':'첨부파일 추가하기'}</div>
            <i className="ii-export"></i>
          </label>
          <p className="mt-2 text-[#f00]">첨부파일에 ","가 포함되지 않도록 주의해주세요.</p>
        </div>
        <div className="flex items-center justify-end space-x-4 mt-4">
          <button onClick={() => navigate(-1)}>취소</button>
          <button className="white" onClick={submit}>
            {state ? "수정" : "등록"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditorSelectForm;
