import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { CommonCode } from "./CommonCode";
import { LectureCourse } from "./LectureCourse";
import { LectureCategoryConfig } from "./LectureCategoryConfig";

@Index("idx_secondary_common_code_1", ["secondaryCodeId", "primaryCodeId"], {
  unique: true,
})
@Index("fk_common_code_secondary_group_code_id", ["primaryCodeId"], {})
@Entity("common_code_secondary", { schema: "dentin" })
export class CommonCodeSecondary {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "secondary_code_id",
    comment: "서브 ID",
  })
  secondaryCodeId: string;

  @Column("bigint", { name: "primary_code_id" })
  primaryCodeId: string;

  @Column("varchar", { name: "secondary_code_name", length: 128 })
  secondaryCodeName: string;

  @Column("tinyint", {
    name: "display_order",
    nullable: true,
    comment: "표시 순서",
  })
  displayOrder: number | null;

  @Column("varchar", {
    name: "description",
    nullable: true,
    comment: "코드 설명",
    length: 256,
  })
  description: string | null;

  @ManyToOne(
    () => CommonCode,
    (commonCode) => commonCode.commonCodeSecondaries,
    { onDelete: "RESTRICT", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "primary_code_id", referencedColumnName: "codeId" }])
  primaryCode: CommonCode;

  @OneToMany(
    () => LectureCourse,
    (lectureCourse) => lectureCourse.commonCodeSecondary
  )
  lectureCourses: LectureCourse[];

  @OneToMany(
    () => LectureCategoryConfig,
    (lectureCategoryConfig) => lectureCategoryConfig.secondaryCode
  )
  lectureCategoryConfigs: LectureCategoryConfig[];
}
