import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { CommonCode } from "./CommonCode";
import { CommonCodeSecondary } from "./CommonCodeSecondary";

@Index("primary_code_id", ["primaryCodeId"], {})
@Index("secondary_code_id", ["secondaryCodeId"], {})
@Entity("lecture_category_config", { schema: "dentin" })
export class LectureCategoryConfig {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id: string;

  @Column("bit", {
    name: "is_secondary",
    nullable: true,
    default: () => "'b'0''",
  })
  isSecondary: boolean | null;

  @Column("bigint", { name: "primary_code_id", nullable: true })
  primaryCodeId: string | null;

  @Column("bigint", { name: "secondary_code_id", nullable: true })
  secondaryCodeId: string | null;

  @Column("bit", { name: "published", nullable: true, default: () => "'b'0''" })
  published: boolean | null;

  @ManyToOne(
    () => CommonCode,
    (commonCode) => commonCode.lectureCategoryConfigs,
    { onDelete: "SET NULL", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "primary_code_id", referencedColumnName: "codeId" }])
  primaryCode: CommonCode;

  @ManyToOne(
    () => CommonCodeSecondary,
    (commonCodeSecondary) => commonCodeSecondary.lectureCategoryConfigs,
    { onDelete: "SET NULL", onUpdate: "RESTRICT" }
  )
  @JoinColumn([
    { name: "secondary_code_id", referencedColumnName: "secondaryCodeId" },
  ])
  secondaryCode: CommonCodeSecondary;
}
