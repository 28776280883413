import dayjs from "dayjs";
import ReactPaginate from "react-paginate";
import { AdminChatMessage } from "../../entities/entities/AdminChatMessage";

const ChatTable = ({
  state,
  onPageClick,
  page,
}: {
  state: [AdminChatMessage[], number];
  onPageClick: (data: { selected: number }) => void;
  page: number;
}) => {
  const data = state && state[0];

  return (
    <>
      <div className="table">
        <div className="row header">
          <div className="w-2/12">발송일시</div>
          <div className="w-8/12">내용</div>
          <div className="w-1/12"></div>
        </div>
        {data?.map((item) => {
          return (
            <div className="row">
              <div className="w-2/12">
                {dayjs(item.regDate).format("YYYY.MM.DD hh:mm")}
              </div>
              <div className="w-8/12">
                <div className="content">{item.message}</div>
              </div>
              <div className="w-1/12 justify-center">
              </div>
            </div>
          );
        })}
      </div>
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={state && state[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
    </>
  );
};

export default ChatTable;
