import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { JobOpening } from "../../entities/entities/JobOpening";
import { EntityService } from "../../services/entity.service";
import Popup from "../Popup";

const entityService = new EntityService();

const RecruitTable = () => {
  const [recruitList, setRecruitList] = useState<[JobOpening[], number] | null>(
    null
  );
  const [deletePopup, setDeletePopup] = useState<JobOpening | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/board/recruit?page=${selected}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchRecruits(page);
      console.log(res)
      setRecruitList(res);
    })();
  }, [page]);

  if (!recruitList) return <></>;
  return (
    <div className="table">
      <div className="row header">
        <div className="w-3/12 ml-4">병원명</div>
        <div className="w-6/12">공고제목</div>
        <div className="w-1/12 justify-center">조회수</div>
        <div className="w-1/12 justify-center">지원자수</div>
        <div className="w-1/12 justify-center">수정</div>
        <div className="w-1/12 justify-center">삭제</div>
      </div>
      {recruitList[0].map((recruit, idx) => (
        <div className="row" key={idx}>
          <div className="w-3/12 ml-4">{recruit.clinicName}</div>
          <div className="w-6/12">
            <a
              href={`https://dent-in.co.kr/jobposting/${recruit.jobId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer hover:text-main"
            >
              {recruit.title}
            </a>
          </div>
          <div className="w-1/12 justify-center">{recruit.viewCount}</div>
          <div className="w-1/12 justify-center text-main">
            {(recruit as any).applicationCount}명
          </div>
          <div className="w-1/12 justify-center">
            <button>
              <a href={`https://dent-in.co.kr/landing?id=${recruit.memberId}&login_type=2&returnUrl=${encodeURIComponent(`/jobposting-form?job_id=${recruit.jobId}&mode=edit&master=on`)}`}
                 target="_blank"
                 rel="noopener noreferrer"
                 className="cursor-pointer hover:text-main">수정</a>
            </button>
          </div>
          <div className="w-1/12 justify-center">
            <button onClick={() => setDeletePopup(recruit)}>삭제</button>
          </div>
        </div>
      ))}
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={recruitList[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
      {deletePopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteJobOpening(deletePopup.jobId);
              setDeletePopup(null);
              setRecruitList((cur) => {
                if (!cur) return cur;
                cur[0] = cur[0].filter(
                  (item) => item.jobId !== deletePopup.jobId
                );
                return [...cur];
              });
              alert("삭제 완료되었습니다");
            } catch {
              alert("에러 발생");
            }
          }}
          close={() => setDeletePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
};

export default RecruitTable;
