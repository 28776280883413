import { Column, Entity } from "typeorm";

@Entity("temp_location", { schema: "dentin" })
export class TempLocation {
  @Column("varchar", { name: "name", nullable: true, length: 64 })
  name: string | null;

  @Column("point", { name: "location", nullable: true })
  location: string | null;
}
