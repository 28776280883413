import dayjs from "dayjs";
import {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Popup from "../../components/Popup";
import { ClinicMember } from "../../entities/entities/ClinicMember";
import { EntityService } from "../../services/entity.service";
import { toYYYYMMDD } from "../../services/utils/repository-util.service";


const entityService = new EntityService();
interface Location {
  latitude:number;
  longitude:number;
}
export default function ClinicMemberDetail() {
  const [withdrawPopup, setWithdrawPopup] = useState<boolean>(false);
  const [addressPopup, setAddressPopup] = useState<boolean>(false);
  const [memberInfo, setMemberInfo] = useState<ClinicMember | null>(null);
  const [memberBoard, setMemberBoard] = useState<any>(null);
  const [lectureCount, setLectureCount] = useState<any>(null);
  const [takeLegalLecture, setTakeLegalLecture] = useState<any>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  let latRef = useRef<HTMLInputElement>(null);
  let longRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!params.id) {
      navigate(-1);
      return;
    }
    (async () => {
      const res = await entityService.fetchHospitalUserDetail(
        params.id as string
      );
      setMemberInfo(res);
    })();
    (async () => {
      const res = await entityService.fetchBoardClinicMember(
        params.id as string
      );
      setMemberBoard(res);
    })();
    (async () => {
      const res = await entityService.fetchUserLectureCount(
        "clinic_member",
        params.id as string
      );
      setLectureCount(res);
    })();
    (async () => {
      const res = await entityService.fetchUserTakeLectureList(
        params.id as string
      );
      setTakeLegalLecture(res)
      console.log(res)
    })();
  }, [navigate, params.id]);
  const getLocation = async () =>{
    await (async () => {
      const res = await entityService.fetchUserLocation(
        params.id as string
      );
      setLocation(res)
      setAddressPopup(true)
    })();
  }

  const completeLecture = async (lectureId) => {
    await (async () => {
      let flag = window.confirm('수강 완료 처리하시겠습니까?\n완료 시간은 수강 시작일 + 60분입니다.')
      if(flag){
        const res = await entityService.updateUserLecture(
          params.id as string,
          lectureId as number
        );
        if(res) {
          alert('수강 완료 처리 되었습니다.')
          window.location.reload()
        }
      }


    })();
  }
  if (!memberInfo) return <></>;
  return (
    <div className="inner page">
      <div className="page-tit">회원정보 상세</div>
      <div className="bg-main text-white py-1 px-3 mt-8">개인정보</div>
      <div className="flex mt-5">
        <div className="w-1/2 flex justify-between">
          <div className="w-1/4 bg-[#F3F3F3] rounded-xl flex flex-col items-center py-8 font-semibold">
            <div className="memberInfo-cell">아이디</div>
            <div className="memberInfo-cell">병원명</div>
            <div className="memberInfo-cell">이메일</div>
            <div className="memberInfo-cell">회원권한</div>
            <div className="memberInfo-cell">대표자명</div>
            <div className="memberInfo-cell">병원분류</div>
          </div>
          <div className="w-8/12 flex flex-col  py-8">
            <div className="memberInfo-cell">{memberInfo.memberId}</div>
            <div className="memberInfo-cell">{memberInfo.clinicName}</div>
            <div className="memberInfo-cell">{memberInfo.email}</div>
            <div className="memberInfo-cell">병원회원</div>
            <div className="memberInfo-cell">{memberInfo.repName}</div>
            <div className="memberInfo-cell">{memberInfo.clinicType}</div>
          </div>
        </div>
        <div className="w-1/2 flex justify-between">
          <div className="w-1/4 bg-[#F3F3F3] rounded-xl flex flex-col items-center py-8 font-semibold">
            <div className="memberInfo-cell">비밀번호</div>
            <div className="memberInfo-cell">사업자번호</div>
            <div className="memberInfo-cell">주소</div>
            <div className="memberInfo-cell">성별</div>
            <div className="memberInfo-cell">면허번호</div>
            <div className="memberInfo-cell">연락처</div>
            <div className="memberInfo-cell">홈페이지</div>
          </div>
          <div className="w-8/12 flex flex-col py-8">
            <div className="memberInfo-cell">****</div>
            <div className="memberInfo-cell">{memberInfo.bizRegNumber}</div>
            <div className="memberInfo-cell">
              <p className='flex-col'>
                {memberInfo.address}
              </p>
              <button className='blue ml-5' onClick={() => getLocation()}>수정</button>
            </div>
            <div className="memberInfo-cell">
              {memberInfo.repGender === "male" ? "남성" : "여성"}
            </div>
            <div className="memberInfo-cell">{memberInfo.repLicenceNumber}</div>
            <div className="memberInfo-cell">{memberInfo.phoneNumber}</div>
            <div className="memberInfo-cell">{memberInfo.homepageUrl}</div>
          </div>
        </div>
      </div>
      <div className="bg-main text-white py-1 px-3 mt-8">가입정보</div>
      <div className="flex">
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              회원가입일
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              회원중지
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {memberInfo.createdAt
                ? dayjs(new Date(memberInfo.createdAt)).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : ""}
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {memberInfo.activeFlag ? "X" : "O"}
            </div>
          </div>
        </div>
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              최근접속일
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              접속 기기
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {memberInfo.lastLoginDate
                ? dayjs(new Date(memberInfo.lastLoginDate)).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : ""}
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {memberInfo.lastLoginDevice &&
                (memberInfo.lastLoginDevice === "W" ? "웹" : "모바일")}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-main text-white py-1 px-3 mt-8">등록 게시글</div>
      {memberBoard && (
        <div className="flex px-5 py-5 border-b-[1px] border-[#ccc]">
          <div className="w-1/6 flex">구인공고 게시판</div>
          <div className="w-3/6 flex font-semibold">{memberBoard.title}</div>
          <div className="w-1/6 flex justify-center text-[#666]">
            {memberBoard.reg_date
              ? dayjs(new Date(memberBoard.reg_date)).format("YYYY-MM-DD HH:mm")
              : ""}
            작성
          </div>
          <div className="w-1/6 flex justify-end">
            조회수
            <span className="text-main ml-2">{memberBoard.view_count}</span>
          </div>
        </div>
      )}
      <div className="bg-main text-white py-1 px-3 mt-8">이용 기록</div>
      <div className="flex">
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              이수 강의 수
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {lectureCount}개
            </div>
          </div>
        </div>
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              순 방문수
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              추후 구현
            </div>
          </div>
        </div>
      </div>
      <div className="bg-main text-white py-1 px-3 mt-8">수강중인 법정의무교육</div>
      <div className="flex">
        <div className="table" style={{marginTop:0}}>
          <div
            className="row header"
            style={{ background: "#F4F6F8"}}
          >
            <div className="w-[60%] justify-center text-[#637381]">수강명</div>
            <div className="w-[20%] justify-center text-[#637381]">시작일</div>
            <div className="w-[20%] justify-center text-[#637381]">완료 처리</div>
          </div>
          {takeLegalLecture && takeLegalLecture?.map((k)=>{
              return(<div className="row">
                <div className="w-[60%] justify-center">{k.lecture_name}</div>
                <div className="w-[20%] justify-center">{dayjs(new Date(k.start_date)).format("YYYY-MM-DD HH:mm")}</div>
                <div className="w-[20%] justify-center">
                  <button
                    className="dark"
                    onClick={() => {
                      completeLecture(k.lecture_id)
                    }
                    }
                  >
                    수강 완료 처리
                  </button>
                </div>
              </div>)
          }
          )}
        </div>
      </div>
      <div className="flex justify-end mt-20">
        <button onClick={() => navigate("/member/clinic")}>취소</button>
        <button className="dark" onClick={() => setWithdrawPopup(true)}>
          탈퇴
        </button>
      </div>
      {withdrawPopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteMember(memberInfo.memberId, true);
            } catch (error) {
              console.error(error);
              alert("회원 탈퇴 처리 중 에러가 발생했습니다.");
            }

            setWithdrawPopup(false);
            navigate("/member/clinic");
          }}
          close={() => setWithdrawPopup(false)}
        >
          <div className="flex justify-center font-semibold">
            탈퇴처리 하시겠습니까?
          </div>
        </Popup>
      )}
      {addressPopup && (
        <Popup
          submit={async () => {
            try {

              if(Number(latRef.current!.value) < 120 || Number(latRef.current!.value) > 140) {
                alert("위도의 크기는 120~140 사이입니다.");
                return;
              }
              if(Number(longRef.current!.value) < 30 || Number(longRef.current!.value) > 50) {
                alert("경도의 크기는 30~50 사이입니다.");
                return;
              }
              const data = {
                latitude:latRef.current!.value,
                longitude:longRef.current!.value
              }
              await entityService.updateHospitalUserLocation(memberInfo.memberId,data );
            } catch (error) {
              console.error(error);
              alert("위치 수정 중 에러가 발생했습니다.");
            }

            setAddressPopup(false);
            navigate("/member/clinic");
          }}
          close={() => setAddressPopup(false)}
        >
          <div>
            <ul className='flex'>
              <li className='w-1/2'>
                <label htmlFor="" className='small-heading'>위도 : </label>
                <input type="text" className='w-[200px] border-2' defaultValue={location?.latitude} ref={latRef}/>
              </li>
              <li className='w-1/2'>
                <label htmlFor="">경도 : </label>
                <input type="text" className='w-[200px] border-2'  defaultValue={location?.longitude} ref={longRef}/>
              </li>
            </ul>
          </div>
          <div className="flex justify-center font-semibold mt-5">
            위치를 수정 하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
}
