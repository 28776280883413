import { Column, Entity } from "typeorm";

@Entity("work_area", { schema: "dentin" })
export class WorkArea {
  @Column("varchar", {
    primary: true,
    name: "city",
    comment: "도시명",
    length: 64,
  })
  city: string;

  @Column("varchar", {
    primary: true,
    name: "district",
    comment: "지역(구)명",
    length: 64,
  })
  district: string;

  @Column("varchar", {
    name: "place",
    nullable: true,
    comment: "장소명",
    length: 64,
  })
  place: string | null;

  @Column("point", { name: "location", comment: "좌표 정보" })
  location: string;
}
