import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { EducationNotice } from "../../entities/entities/EducationNotice";
import { Notice } from "../../entities/entities/Notice";
import { EntityService } from "../../services/entity.service";
import { toYYYYMMDD } from "../../services/utils/repository-util.service";

const NoticeTable = ({
  state,
  onPageClick,
  page,
  isEduNotice = false,
}: {
  state: [Notice[], number];
  onPageClick: (data: { selected: number }) => void;
  page: number;
  isEduNotice?: boolean;
}) => {
  const data = state && state[0];
  const navigate = useNavigate();

  const deleteClick = async (id) => {
    const entityService = new EntityService();
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        if (isEduNotice) {
          await entityService.delete(EducationNotice, id);
        } else {
          await entityService.delete(Notice, id);
        }
        alert("삭제가 완료되었습니다.");
        window.location.reload();
      } catch (e) {
        console.log(e);
        alert("에러가 발생했습니다.");
      }
    }
  };

  return (
    <>
      <div className="table">
        <div className="row header">
          <div className="w-2/12">카테고리</div>
          <div className="w-6/12">제목</div>
          <div className="w-2/12">등록일</div>
          <div className="w-1/12 justify-center">수정</div>
          <div className="w-1/12 justify-center">삭제</div>
        </div>
        {data?.map((item) => {
          return (
            <div className="row" key={item.noticeId}>
              {item.noticeType === 1 && (
                <div className="w-2/12">일반 공지사항</div>
              )}
              {item.noticeType === 2 && (
                <div className="w-2/12">이벤트 공지사항</div>
              )}
              {item.noticeType === 3 && (
                <div className="w-2/12">온라인교육 공지사항</div>
              )}
              {item.noticeType === 4 && (
                <div className="w-2/12">실습치과 공지사항</div>
              )}

              <div className="w-6/12">{item.title}</div>
              <div className="w-2/12">{toYYYYMMDD(new Date(item.regDate))}</div>
              <div className="w-1/12 justify-center">
                <button
                  className="dark"
                  onClick={() => {
                      let url = isEduNotice?"/communication/edu-noti/write":"/communication/noticewrite"
                      navigate(url, { state: item })
                    }
                  }
                >
                  수정
                </button>
              </div>
              <div className="w-1/12 justify-center">
                <button onClick={() => deleteClick(item.noticeId)}>삭제</button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={state && state[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
    </>
  );
};

export default NoticeTable;
