import {
  Column,
  Entity,
  Index,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { JobAction } from "./JobAction";
import { ResumeGroup } from "./ResumeGroup";
import { ClinicMemberWish } from "./ClinicMemberWish";
import { Occupation } from "./Occupation";

@Index("fk_resume_group_id", ["groupId"], {})
@Index("idx_resume_public_flag", ["publicFlag"], {})
@Index("idx_resume_dentist_flag", ["dentistFlag"], {})
@Index("idx_resume_composite_1", ["city", "district"], {})
@Index("idx_resume_reg_date", ["regDate"], {})
@Index("idx_resume_search_text", ["searchText"], { fulltext: true })
@Entity("resume", { schema: "dentin" })
export class Resume {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "resume_id",
    comment: "이력서 ID",
  })
  resumeId: string;

  @Column("bigint", { name: "group_id", comment: "이력서 그룹 고유 번호" })
  groupId: string;

  @Column("varchar", {
    name: "image_filename",
    nullable: true,
    comment: "이미지 파일명",
    length: 256,
  })
  imageFilename: string | null;

  @Column("varchar", { name: "name", comment: "회원명", length: 64 })
  name: string;

  @Column("date", { name: "birth_date", comment: "생년월일" })
  birthDate: string;

  @Column("varchar", { name: "email", comment: "회원 email", length: 64 })
  email: string;

  @Column("bit", { name: "public_email", comment: "회원 email 공개 여부" })
  publicEmail: boolean;

  @Column("varchar", {
    name: "phone_number",
    comment: "회원 전화 번호",
    length: 32,
  })
  phoneNumber: string;

  @Column("bit", {
    name: "public_phone_number",
    comment: "회원 전화 번호 공개 여부",
  })
  publicPhoneNumber: boolean;

  @Column("varchar", { name: "city", comment: "도시명", length: 64 })
  city: string;

  @Column("varchar", { name: "district", comment: "지역(구)명", length: 64 })
  district: string;

  @Column("varchar", {
    name: "region",
    comment: "지역(도시+지역구) - 검색시 사용",
    length: 128,
  })
  region: string;

  @Column("varchar", { name: "address", comment: "회원 주소", length: 256 })
  address: string;

  @Column("varchar", {
    name: "extra_address",
    nullable: true,
    comment: "추가 주소",
    length: 256,
  })
  extraAddress: string | null;

  @Column("varchar", { name: "title", comment: "이력서 제목", length: 128 })
  title: string;

  @Column("longtext", {
    name: "occupation_info",
    comment: "직종 정보 (희망 직종)",
  })
  occupationInfo: string;

  @Column("longtext", {
    name: "work_info",
    comment: "근무 정보 (희망 업무, 희망 근무 형태)",
  })
  workInfo: string;

  @Column("longtext", { name: "salary_info", comment: "연봉 정보" })
  salaryInfo: string;

  @Column("longtext", {
    name: "last_education_info",
    nullable: true,
    comment: "최종 학력 정보",
  })
  lastEducationInfo: string | null;

  @Column("longtext", {
    name: "work_experience_info",
    nullable: true,
    comment: "경력 정보",
  })
  workExperienceInfo: string | null;

  @Column("bigint", {
    name: "work_experience_month",
    nullable: true,
    comment: "총 경력 기간",
  })
  workExperienceMonth: string | null;

  @Column("longtext", {
    name: "license_info",
    nullable: true,
    comment: "자격증 정보",
  })
  licenseInfo: string | null;

  @Column("text", {
    name: "cover_letter",
    nullable: true,
    comment: "자기 소개서",
  })
  coverLetter: string | null;

  @Column("varchar", {
    name: "cover_letter_filename",
    nullable: true,
    comment: "자기소개서 파일명",
    length: 256,
  })
  coverLetterFilename: string | null;

  @Column("bit", { name: "public_flag", comment: "이력서 공개 여부" })
  publicFlag: boolean;

  @Column("bit", {
    name: "public_class_complete",
    comment: "치과인 강좌 이수 목록 공개 여부",
  })
  publicClassComplete: boolean;

  @Column("tinyint", {
    name: "dentist_flag",
    comment: "치과의사 직종 여부 (1: 치과의사, 2: 스탭, 3: 둘 다)",
  })
  dentistFlag: number;

  @Column("int", {
    name: "view_count",
    comment: "조회수",
    default: () => "'0'",
  })
  viewCount: number;

  @Column("text", {
    name: "search_text",
    nullable: true,
    comment: "검색 문자열",
  })
  searchText: string | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("string", { name: "member_id", comment: "멤버 아이디" })
  memberId: string;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @OneToMany(() => JobAction, (jobAction) => jobAction.resume)
  jobActions: JobAction[];

  @ManyToOne(() => ResumeGroup, (resumeGroup) => resumeGroup.resumes, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "group_id", referencedColumnName: "groupId" }])
  group: ResumeGroup;

  @OneToMany(
    () => ClinicMemberWish,
    (clinicMemberWish) => clinicMemberWish.resume
  )
  clinicMemberWishes: ClinicMemberWish[];

  @ManyToMany(() => Occupation, (occupation) => occupation.resumes)
  @JoinTable({
    name: "resume_occupation",
    joinColumns: [{ name: "resume_id", referencedColumnName: "resumeId" }],
    inverseJoinColumns: [
      { name: "occupation_id", referencedColumnName: "occupationId" },
    ],
    schema: "dentin",
  })
  occupations: Occupation[];
}
