import { useEffect, useState } from "react";
import Ranking from "../../components/statistics/Ranking";
import { EntityService } from "../../services/entity.service";

const entityService = new EntityService();
const RankingPage = () => {
  const [rankingData, setRankingData] = useState<any>();

  useEffect(() => {
    (async () => {
      const rankings = await entityService.fetchRanks();
      setRankingData(rankings);
      console.log(rankings);
    })();
  } ,[])

  return (
    <div className="max-w-[1050px] m-auto pb-28">
      <div className="page-tit mb-9">순위</div>
      <div className="px-5 w-[1050px]">
        <div className="flex items-start justify-between mb-7">
          <Ranking
            title="게시글 조회 순위(채용공고)"
            data={rankingData?.boardViewRecruitRankBoard}
            viewField="조회수"
          />
          <Ranking
            title="게시글 조회 순위(인재정보)"
            data={rankingData?.boardViewHumanResourecRankBoard}
            viewField="조회수"
          />
        </div>
        <div className="flex items-start justify-between mb-7">
          <Ranking
            title="게시글 조회 순위(온라인교육)"
            data={rankingData?.boardViewVideoRankBoard}
            viewField="조회수"
          />
          <Ranking
            title="온라인교육 재생수"
            data={rankingData?.boardViewVideoViewCountRank}
            viewField="재생수"
          />
        </div>
        <div className="flex items-start justify-between mb-7">
          <Ranking
            title="평균재생시간 순위"
            data={rankingData?.videoPlayTimeRank}
            viewField="재생시간"
          />
          <Ranking
            title="신규 수강 순위"
            data={rankingData?.videoNewplayRank}
            viewField="재생수"
          />
        </div>
        <div className="flex items-start justify-between mb-7"></div>
      </div>
      <div></div>
    </div>
  );
};

export default RankingPage;
