import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "../../components/Popup";
import { Member } from "../../entities/entities/Member";
import { EntityService } from "../../services/entity.service";
import { toYYYYMMDD } from "../../services/utils/repository-util.service";

const entityService = new EntityService();

export default function PersonalMemberDetail() {
  const [withdrawPopup, setWithdrawPopup] = useState<boolean>(false);
  const [memberInfo, setMemberInfo] = useState<Member | null>(null);
  const [memberBoard, setMemberBoard] = useState<any>(null);
  const [lectureCount, setLectureCount] = useState<any>(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      navigate(-1);
      return;
    }
    (async () => {
      const res = await entityService.fetchMemberUserDetail(
        params.id as string
      );
      setMemberInfo(res);
    })();
    (async () => {
      const res = await entityService.fetchBoardMember(params.id as string);
      setMemberBoard(res);
    })();
    (async () => {
      const res = await entityService.fetchUserLectureCount(
        "member",
        params.id as string
      );
      setLectureCount(res);
    })();
  }, [navigate, params.id]);

  if (!memberInfo) return <></>;
  return (
    <div className="inner page">
      <div className="page-tit">회원정보 상세</div>
      <div className="bg-main text-white py-1 px-3 mt-8">개인정보</div>
      <div className="flex mt-5">
        <div className="w-1/2 flex justify-between">
          <div className="w-1/4 bg-[#F3F3F3] rounded-xl flex flex-col items-center py-8 font-semibold">
            <div className="memberInfo-cell">아이디</div>
            <div className="memberInfo-cell">이름</div>
            <div className="memberInfo-cell">이메일</div>
            <div className="memberInfo-cell">회원권한</div>
            <div className="memberInfo-cell">생년월일</div>
          </div>
          <div className="w-8/12 flex flex-col py-8">
            <div className="memberInfo-cell">{memberInfo.memberId}</div>
            <div className="memberInfo-cell">{memberInfo.name}</div>
            <div className="memberInfo-cell">{memberInfo.email}</div>
            <div className="memberInfo-cell">{memberInfo.occupation}</div>
            <div className="memberInfo-cell">{memberInfo.birthDate}</div>
          </div>
        </div>
        <div className="w-1/2 flex justify-between">
          <div className="w-1/4 bg-[#F3F3F3] rounded-xl flex flex-col items-center py-8 font-semibold">
            <div className="memberInfo-cell">비밀번호</div>
            <div className="memberInfo-cell">연락처</div>
            <div className="memberInfo-cell">성별</div>
            <div className="memberInfo-cell">면허번호</div>
          </div>
          <div className="w-8/12 flex flex-col py-8">
            <div className="memberInfo-cell">****</div>
            <div className="memberInfo-cell">{memberInfo.phoneNumber}</div>
            <div className="memberInfo-cell">
              {memberInfo.gender === "male" ? "남성" : "여성"}
            </div>
            <div className="memberInfo-cell">{memberInfo.licenceNumber}</div>
          </div>
        </div>
      </div>
      <div className="bg-main text-white py-1 px-3 mt-8">가입정보</div>
      <div className="flex">
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              회원가입일
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              회원중지
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {memberInfo.createdAt
                ? dayjs(new Date(memberInfo.createdAt)).format("YYYY-MM-DD HH:mm")
                : ""}
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {memberInfo.activeFlag ? "X" : "O"}
            </div>
          </div>
        </div>
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              최근접속일
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              접속 기기
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {memberInfo.lastLoginDate
                ? dayjs(new Date(memberInfo.lastLoginDate)).format("YYYY-MM-DD HH:mm")
                : ""}
            </div>
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
            {memberInfo.lastLoginDevice &&
                (memberInfo.lastLoginDevice === "W" ? "웹" : "모바일")}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-main text-white py-1 px-3 mt-8">등록 게시글</div>
      {memberBoard && (
        <div className="flex px-5 py-5 border-b-[1px] border-[#ccc]">
          <div className="w-1/6 flex">인재정보 게시판</div>
          <div className="w-3/6 flex font-semibold">{memberBoard.title}</div>
          <div className="w-1/6 flex justify-center text-[#666]">
            {dayjs(new Date(memberBoard.reg_date)).format("YYYY-MM-DD HH:mm")} 작성
          </div>
          <div className="w-1/6 flex justify-end">
            조회수
            <span className="text-main ml-2">{memberBoard.view_count}</span>
          </div>
        </div>
      )}
      <div className="bg-main text-white py-1 px-3 mt-8">이용 기록</div>
      <div className="flex">
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              이수 강의 수
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              {lectureCount}개
            </div>
          </div>
        </div>
        <div className="w-1/2 flex">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              순 방문수
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
              추후 구현
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-20">
        <button onClick={() => navigate("/member/personal")}>취소</button>
        <button className="dark" onClick={() => setWithdrawPopup(true)}>
          탈퇴
        </button>
      </div>
      {withdrawPopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteMember(memberInfo.memberId);
              alert("삭제되었습니다");
            } catch (error) {
              console.error(error);
              alert('회원 탈퇴 처리 중 에러가 발생했습니다.');
            }

            navigate('/member/personal');
            setWithdrawPopup(false);
          }}
          close={() => setWithdrawPopup(false)}
        >
          <div className="flex justify-center font-semibold">
            탈퇴처리 하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
}
