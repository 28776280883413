import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { Member } from "./Member";
import { JobOpening } from "./JobOpening";

@Index("fk_member_wish_member_id", ["memberId"], {})
@Index("fk_member_wish_job_id", ["jobId"], {})
@Entity("member_wish", { schema: "dentin" })
export class MemberWish {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "병원 회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("bigint", { primary: true, name: "job_id", comment: "재용 공고 ID" })
  jobId: string;

  @Column("text", { name: "memo", nullable: true, comment: "메모" })
  memo: string | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @ManyToOne(() => Member, (member) => member.memberWishes, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: Member;

  @ManyToOne(() => JobOpening, (jobOpening) => jobOpening.memberWishes, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "job_id", referencedColumnName: "jobId" }])
  job: JobOpening;
}
