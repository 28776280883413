import { Column, Entity, Index, PrimaryGeneratedColumn } from "typeorm";

@Index("idx_education_notice_notice_type", ["noticeType"], {})
@Index("idx_education_notice_composite_date", ["startDate", "endDate"], {})
@Index("idx_education_notice_composite_1", ["title", "contents"], {
  fulltext: true,
})
@Entity("education_notice", { schema: "dentin" })
export class EducationNotice {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "notice_id",
    comment: "공지 사항 ID",
  })
  noticeId: string;

  @Column("tinyint", {
    name: "notice_type",
    comment:
      "공지 사항 유형 (1. 공지사항, 2. 이벤트, 3. 온라인교육 4.실습치과)",
  })
  noticeType: number;

  @Column("varchar", { name: "title", comment: "공지 사항 제목", length: 32 })
  title: string;

  @Column("text", {
    name: "contents",
    nullable: true,
    comment: "공지 사항 내용",
  })
  contents: string | null;

  @Column("int", { name: "view_count", comment: "조회 수" })
  viewCount: number;

  @Column("datetime", { name: "start_date", comment: "시작일" })
  startDate: Date;

  @Column("datetime", { name: "end_date", nullable: true, comment: "종료일" })
  endDate: Date | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("tinyint", {
    name: "focus",
    nullable: true,
    comment: "중요공지 여부",
    default: () => "'0'",
  })
  focus: number | null;

  @Column("varchar", {
    name: "file_download_link",
    nullable: true,
    comment: "첨부 파일 다운로드 링크",
    length: 2048,
  })
  fileDownloadLink: string | null;

  @Column("varchar", {
    name: "file_download_filename",
    nullable: true,
    comment: "첨부 파일 이름",
    length: 2048,
  })
  fileDownloadFilename: string | null;
}
