import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EntityService } from "../services/entity.service";
import ReactPaginate from "react-paginate";
import { JobOpening } from "../entities/entities/JobOpening";

const Intern = () => {
  const entityService = new EntityService();
  const location = useLocation();
  const navigate = useNavigate();
  const [internData, setInternData] = useState<any>();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const data = internData && internData[0];

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchInternRecruitList(page, 20);
      setInternData(res)
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, navigate]);
  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/info/admin?page=${selected}`);
  };

  const deleteList = async (id) => {
    if (window.confirm("삭제하시겠습니까?")) {
    try {
      await entityService.delete(JobOpening, id);
      alert("삭제가 완료되었습니다.");
    } catch (e) {
      console.log(e);
      alert('에러가 발생했습니다.');
    } 
  }
  };

  return (
    <div className="inner page w-[1200px]">
      <div className="page-tit mb-[37px]">실습치과공고</div>
      <div>
        <div className="flex items-center justify-evenly py-3 text-white bg-main text-[15px] font-semibold">
          <div className="w-[18%] ml-4">병원명</div>
          <div className="w-[45%]">공고제목</div>
          <div className="w-[20%] flex items-center justify-center">
            카테고리
          </div>
          <div className="w-[5%]">조회수</div>
          <div className="w-[5%]">지원자수</div>
          <div className="w-[5%] flex items-center justify-center">삭제</div>
        </div>
        {data?.map((item) => {
          return (
            <div
              key={item.jobId}
              className="flex items-center justify-evenly py-4 text-[#666] font-semibold border-b border-[#999]"
            >
              <div className="w-[18%] ml-4 truncate">{item.clinicName}</div>
              <div className="w-[45%] truncate">{item.title}</div>
              <div className="w-[20%] flex items-center justify-center">
                {item.occupationInfo
                  .replaceAll(/[`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/gim, "")
                  .replaceAll("occupationlist", "")}
              </div>
              <div className="w-[5%]">{item.viewCount}</div>
              <div className="w-[5%] text-main">{(item as any).applicationCount}</div>
              <div
                className="w-[5%] bg-[#C1C1C1] text-white rounded-sm text-center"
                onClick={() => deleteList(item.jobId)}
              >
                삭제
              </div>
            </div>
          );
        })}
      </div>
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
    </div>
  );
};

export default Intern;
