import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { Lecture } from "./Lecture";

@Index("fk_lecture_take_lecture_id", ["lectureId"], {})
@Entity("lecture_take", { schema: "dentin" })
export class LectureTake {
  @Column("tinyint", {
    primary: true,
    name: "member_type",
    comment: "회원 유형 (1: 개인회원, 2: 병원 회원, 3: 관리자 )",
  })
  memberType: number;

  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("bigint", { primary: true, name: "lecture_id", comment: "강좌 ID" })
  lectureId: string;

  @Column("bit", { name: "collect_flag", comment: "집합 교육 여부" })
  collectFlag: boolean;

  @Column("int", {
    name: "view_time",
    comment: "시청 시간",
    default: () => "'0'",
  })
  viewTime: number;

  @Column("datetime", { name: "start_date", comment: "시작 일시" })
  startDate: Date;

  @Column("datetime", {
    name: "complete_date",
    nullable: true,
    comment: "완료 일시 (수강 완료시에 설정)",
  })
  completeDate: Date | null;

  @ManyToOne(() => Lecture, (lecture) => lecture.lectureTakes, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "lecture_id", referencedColumnName: "lectureId" }])
  lecture: Lecture;
}
