import { EntityService } from "../../services/entity.service";
import DutyNoticeTable from "../../components/communication/DutyNoticeTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {DutyNotice} from "../../entities/entities/DutyNotice";

const EducationNoticePage = () => {
  const entityService = new EntityService();
  const [noticePageData, setNoticePageData] = useState<
    [DutyNotice[], number] | null
  >(null);
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchDutyNotcies(page, 20);
      setNoticePageData(res);
    })();
  }, [page]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/communication/edu-noti?page=${selected}`);
  };

  if (!noticePageData) return <></>;

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <div className="page-tit mr-5">공지사항</div>
        </div>
        <div>
          <Link to="/communication/duty-noti/write">
            <button className="blue">등록하기</button>
          </Link>
        </div>
      </div>
      <DutyNoticeTable
        state={noticePageData}
        onPageClick={onPageClick}
        page={page}
        isEduNotice={true}
      />
    </div>
  );
};

export default EducationNoticePage;
