import { Column, Entity, Index, PrimaryGeneratedColumn } from "typeorm";

@Index("idx_education_notice_notice_type", ["noticeType"], {})
@Index("idx_education_notice_composite_date", ["startDate", "endDate"], {})
@Index("idx_education_notice_composite_1", ["title", "contents"], {
  fulltext: true,
})
@Entity("duty_notice", { schema: "dentin" })
export class DutyNotice {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "notice_id",
    comment: "공지 사항 ID",
  })
  noticeId: string;

  @Column("varchar", { name: "title", comment: "공지 사항 제목", length: 32 })
  title: string;

  @Column("text", {
    name: "contents",
    nullable: true,
    comment: "공지 사항 내용",
  })
  contents: string | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;
}
