import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { Resume } from "../../entities/entities/Resume";
import { EntityService } from "../../services/entity.service";
import Popup from "../Popup";

const entityService = new EntityService();
const ResumeTable = () => {
  const [resumeList, setResumeList] = useState<[Resume[], number] | null>(null);
  const [deletePopup, setDeletePopup] = useState<Resume | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/board/resume?page=${selected}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchResumeList(page);
      console.log(res)
      setResumeList(res);
    })();
  }, [page]);

  if (!resumeList) return <></>;
  return (
    <div className="table">
      <div className="row header">
        <div className="w-2/12 ml-4">이름</div>
        <div className="w-6/12">이력서제목</div>
        <div className="w-2/12 justify-center">조회수</div>
        <div className="w-1/12 justify-center">면접제안수</div>
        <div className="w-1/12 justify-center">수정</div>
        <div className="w-1/12 justify-center">삭제</div>
      </div>
      {resumeList[0].map((resume, idx) => (
        <div className="row" key={idx}>
          <div className="w-2/12 ml-4">{resume.name}</div>
          <div className="w-6/12">
            <a
              href={`https://dent-in.co.kr/resume/${resume.resumeId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer hover:text-main"
            >
              {resume.title}
            </a>
          </div>
          <div className="w-2/12 justify-center">{resume.viewCount}</div>
          <div className="w-1/12 text-main justify-center">
            {(resume as any).offerCount}건
          </div>
          <div className="w-1/12 justify-center">
            <button>
              <a href={`https://dent-in.co.kr/landing?id=${resume.memberId}&returnUrl=${encodeURIComponent(`/resume-form?resume_id=${resume.resumeId}&mode=edit&master=on`)}`}
                 target="_blank"
                 rel="noopener noreferrer"
                 className="cursor-pointer hover:text-main">수정</a>
            </button>
          </div>
          <div className="w-1/12 justify-center">
            <button onClick={() => setDeletePopup(resume)}>삭제</button>
          </div>
        </div>
      ))}
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={resumeList[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
      {deletePopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteResume(parseInt(deletePopup.resumeId));
              setDeletePopup(null);
              setResumeList((cur) => {
                if (!cur) return cur;
                cur[0] = cur[0].filter(
                  (item) => item.resumeId !== deletePopup.resumeId
                );
                return [...cur];
              });
              alert("삭제 완료되었습니다");
            } catch {
              alert("에러 발생");
            }
          }}
          close={() => setDeletePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
};

export default ResumeTable;
