import { Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Category from "./pages/board/Category";
import CategoryWrite from "./pages/board/CategoryWrite";
import Home from "./pages/Home";
import AdminPage from "./pages/info/AdminPage";
import PolicyPage from "./pages/info/PolicyPage";
import TermsPage from "./pages/info/TermsPage";
import AgreePage from "./pages/info/AgreePage";
import EnvironmentPage from "./pages/info/EnvironmentPage";
import QnaPage from "./pages/communication/QnaPage";
import FaqPage from "./pages/communication/FaqPage";
import NoticePage from "./pages/communication/NoticePage";
import ChatPage from "./pages/communication/ChatPage";
import Login from "./pages/Login";
import Write from "./pages/info/Write";
import AdminRegister from "./pages/info/AdminRegister";
import FaqWrite from "./pages/communication/FaqWrite";
import ChatWrite from "./pages/communication/ChatWrite";
import { useRecoilValue } from "recoil";
import { tokenState } from "./state";
import RecruitPage from "./pages/board/RecruitPage";
import ResumePage from "./pages/board/ResumePage";
// import OralHealthClassTable from "./components/board/OralHealthClassTable";
import EtcClassPage from "./pages/board/EtcClassPage";
import DataPage from "./pages/board/DataPage";
import DutyClassPage from "./pages/board/DutyClassPage";
import DentinClassPage from "./pages/board/DentinClassPage";
import NoticeWrite from "./pages/communication/NoticeWrite";
import BannerWrite from "./pages/BannerWrite";
import DataWrite from "./pages/communication/DataWrite";
import PersonalMemberDetail from "./pages/member/PersonalMemberDetail";
import PersonalMemberPage from "./pages/member/PersonalMemberPage";
import ClinicMemberDetail from "./pages/member/ClinicMemberDetail";
import ClinicMemberPage from "./pages/member/ClinicMemberPage";
import OralHealthClassPage from "./pages/board/OralHealthClassPage";
import DailyPage from "./pages/statistics/DailyPage";
import VisitPage from "./pages/statistics/VisitPage";
import MemberPage from "./pages/statistics/MemberPage";
import RankingPage from "./pages/statistics/RankingPage";
import Intern from "./pages/Intern";
import LectureDetail from "./pages/board/LectureDetail";
import BannerPage from "./pages/BannerPage";
import InternSupport from "./pages/InternSupport";
import EducationNoticePage from "./pages/communication/EducationNoticePage";
import EducationNoticeWrite from "./pages/communication/EducationNoticeWrite";
import DutyNoticeWrite from "./pages/communication/DutyNoticeWrite";
import DutyNoticePage from "./pages/communication/DutyNoticePage";
import ProtectedRoute from "./utils/protectedRoute";
import ContentsPage from "./pages/statistics/ContentsPage";


function App() {
  const token = useRecoilValue(tokenState);
  const isLogin = token || sessionStorage.getItem("token"); // 임시// @ts-ignore

  return (
    <>

      {isLogin ? (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="info/*">
              <Route index element={<AdminPage />} />
              <Route path="admin" element={<AdminPage />} />
              <Route path="termswrite" element={<ProtectedRoute role={['0','1']} isLogin={isLogin} redirectPath='/' outlet={<Write />} />} />
              <Route path="policywrite" element={<ProtectedRoute role={['0','1']} isLogin={isLogin} redirectPath='/' outlet={<Write />} />}  />
              <Route path="agreewrite" element={<ProtectedRoute role={['0','1']} isLogin={isLogin} redirectPath='/' outlet={<Write />} />}  />
              <Route path="adminwrite" element={<ProtectedRoute role={['0','1']} isLogin={isLogin} redirectPath='/' outlet={<AdminRegister />} />}  />
              <Route path="terms" element={<TermsPage />} />
              <Route path="policy" element={<PolicyPage />} />
              <Route path="agree" element={<AgreePage />} />
              <Route path="environment" element={<EnvironmentPage />} />
            </Route>
            <Route path="communication/*">
              <Route index element={<FaqPage />} />
              <Route path="qna" element={<ProtectedRoute role={['0','3']} isLogin={isLogin} redirectPath='/' outlet={<QnaPage />} />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="faqwrite" element={<ProtectedRoute role={['0','3']} isLogin={isLogin} redirectPath='/' outlet={<FaqWrite />} />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="chatwrite" element={<ProtectedRoute role={['0','3']} isLogin={isLogin} redirectPath='/' outlet={<ChatWrite />} />} />
              <Route path="notice" element={<NoticePage />} />
              <Route path="edu-noti" element={<EducationNoticePage />} />
              <Route path="edu-noti/write" element={<ProtectedRoute role={['0','3']} isLogin={isLogin} redirectPath='/' outlet={<EducationNoticeWrite />} />} />
              <Route path="noticewrite" element={<ProtectedRoute role={['0','3']} isLogin={isLogin} redirectPath='/' outlet={<NoticeWrite />} />} />
              <Route path="duty-noti" element={<ProtectedRoute role={['0','3']} isLogin={isLogin} redirectPath='/' outlet={<DutyNoticePage />} />} />
              <Route path="duty-noti/write" element={<ProtectedRoute role={['0','3']} isLogin={isLogin} redirectPath='/' outlet={<DutyNoticeWrite />} />} />
            </Route>
            <Route path="member/*">
              <Route index element={<ClinicMemberPage />} />
              <Route path="clinic" element={<ClinicMemberPage />} />
              <Route path="personal" element={<PersonalMemberPage />} />
              <Route path="personal/:id" element={<PersonalMemberDetail />} />
              <Route path="clinic/:id" element={<ClinicMemberDetail />} />
            </Route>
            <Route path="board/*">
              <Route index element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<Category />} />}></Route>
              <Route path="category" element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<Category />} />}></Route>
              <Route path="category/write" element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<CategoryWrite />} />}></Route>
              <Route path="recruit" element={<ProtectedRoute role={['0','4']} isLogin={isLogin} redirectPath='/' outlet={<RecruitPage />} />}  />
              <Route path="resume" element={<ProtectedRoute role={['0','4']} isLogin={isLogin} redirectPath='/' outlet={<ResumePage />} />} />
              <Route path="duty-class" element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<DutyClassPage />} />} />
              <Route path="dentin-class" element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<DentinClassPage />} />} />
              <Route
                path="dentin-class/:id"
                element={<LectureDetail title="치과인강좌" />}
              />
              <Route
                path="oralhealth-class"
                element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<OralHealthClassPage />} />}
              />
              <Route
                path="oralhealth-class/:id"
                element={<LectureDetail title="구강보건교육" />}
              />
              <Route path="etc-class" element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<EtcClassPage />} />} />
              <Route
                path="etc-class/:id"
                element={<LectureDetail title="기타교육" />}
              />
              <Route path="data" element={<DataPage />} />
              <Route path="datawrite" element={<ProtectedRoute role={['0','2']} isLogin={isLogin} redirectPath='/' outlet={<DataWrite />} />} />
            </Route>
            <Route path="statistics/*">
              <Route index element={<DailyPage />}></Route>
              <Route path="daily" element={<DailyPage />}></Route>
              <Route path="visit" element={<VisitPage />}></Route>
              <Route path="member" element={<MemberPage />}></Route>
              <Route path="ranking" element={<RankingPage />}></Route>
              <Route path="contents" element={<ContentsPage />}></Route>
            </Route>
            <Route path="banner" element={<BannerPage />} />
            <Route path="bannerwrite" element={<ProtectedRoute role={['0','5']} isLogin={isLogin} redirectPath='/' outlet={<BannerWrite/>} />}  />
            <Route path="intern/*">
              <Route index element={<ProtectedRoute role={['0','4']} isLogin={isLogin} redirectPath='/' outlet={<Intern />} />} />
              <Route path="posting" element={<ProtectedRoute role={['0','4']} isLogin={isLogin} redirectPath='/' outlet={<Intern />} />} />
              <Route path="support" element={<ProtectedRoute role={['0','4']} isLogin={isLogin} redirectPath='/' outlet={<InternSupport />} />} />
            </Route>
            <Route path="/login" element={<Login />}></Route>
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="*" element={<Login />}></Route>
        </Routes>
      )}
    </>
  );
}

export default App;
