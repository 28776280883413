import { Link } from "react-router-dom";


const HomeNow = ({ title, data }: { title: string; data: any }) => {
  const titles = [
    { tit: "실시간 채용공고", link: "/board/recruit" },
    { tit: "실시간 실습치과공고", link: "/intern/posting" },
    { tit: "실시간 인재정보", link: "/board/resume" },
  ];
  const linkTo = titles.filter((item) => item.tit === title);

  return (
    <div className="w-[480px]">
      <div className="flex items-end justify-between mb-3">
        <div className="text-[#333] text-[20px] font-bold">{title}</div>
        <Link to={`${linkTo[0].link}`}>
          <div className="text-[#666] font-semibold text-[15px]">더보기 〉</div>
        </Link>
      </div>
      <div className="bg-[#F8F8F8] border-t-[1.5px]  border-[#333] h-64">
        <div className="flex items-center justify-evenly border-b-[1.5px] border-[#CFCFCF] py-2">
          <div className="w-[15%] flex items-center justify-center">최신순</div>
          {(title === "실시간 채용공고" || title === "실시간 실습치과공고") && (
            <div className="w-[25%]">병원명</div>
          )}
          {title === "실시간 인재정보" && <div className="w-[25%]">이름</div>}
          <div className="w-[45%]">제목</div>
          <div className="w-[15%] flex items-center justify-center">조회수</div>
        </div>
        {data?.map((i, idx) => {
          return (
            <div key={idx} className="flex items-center justify-evenly py-2">
              <div className="w-[15%] flex items-center justify-center">
                {idx + 1}
              </div>
              {i.clinicName && (
                <div className="w-[25%] truncate">{i.clinicName}</div>
              )}
              {i.name && <div className="w-[25%] truncate">{i.name}</div>}
              {i.creator && <div className="w-[25%] truncate">{i.creator}</div>}
              <div className="w-[45%] truncate">{i.title}</div>

              <div className="w-[15%] flex items-center justify-center">
                {i.viewCount}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};;;

export default HomeNow;
