import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { LectureCourseBookmark } from "./LectureCourseBookmark";
import { CommonCode } from "./CommonCode";
import { CommonCodeSecondary } from "./CommonCodeSecondary";
import { Lecture } from "./Lecture";

@Index(
  "idx_lecture_course_composite_1",
  ["startDate", "endDate", "recommendFlag"],
  {}
)
@Index("idx_lecture_course_lecture_type", ["lectureType"], {})
@Index("common_code_id", ["commonCodeId"], {})
@Index("common_code_secondary_id", ["commonCodeSecondaryId"], {})
@Index("idx_lecture_course_composite_2", ["title", "description"], {
  fulltext: true,
})
@Entity("lecture_course", { schema: "dentin" })
export class LectureCourse {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "course_id",
    comment: "강좌 코스 ID",
  })
  courseId: string;

  @Column("tinyint", {
    name: "lecture_type",
    comment:
      "교육 유형 (1. 법정 의무 교육, 2, 치과인 강좌, 3. 구강 보건 교육, 4. 기타 교육)",
  })
  lectureType: number;

  @Column("varchar", { name: "creator", comment: "제작자", length: 64 })
  creator: string;

  @Column("varchar", {
    name: "image_filename",
    nullable: true,
    comment: "image 파일명",
    length: 256,
  })
  imageFilename: string | null;

  @Column("int", { name: "score", comment: "이수 점수" })
  score: number;

  @Column("varchar", { name: "title", comment: "제목", length: 128 })
  title: string;

  @Column("text", { name: "description", nullable: true, comment: "설명" })
  description: string | null;

  @Column("bit", { name: "recommend_flag", comment: "추천 여부" })
  recommendFlag: boolean;

  @Column("datetime", { name: "start_date", comment: "시작일" })
  startDate: Date;

  @Column("datetime", { name: "end_date", nullable: true, comment: "종료일" })
  endDate: Date | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("varchar", {
    name: "course_material_download_link",
    nullable: true,
    comment: "강의 자료",
    length: 2048,
  })
  courseMaterialDownloadLink: string | null;

  @Column("bigint", {
    name: "common_code_id",
    nullable: true,
    comment: "카테고리 대분류",
  })
  commonCodeId: string | null;

  @Column("bigint", {
    name: "common_code_secondary_id",
    nullable: true,
    comment: "카테고리 소분류",
  })
  commonCodeSecondaryId: string | null;

  @Column("varchar", {
    name: "course_material_download_filename",
    nullable: true,
    comment: "강의 자료 이름",
    length: 256,
  })
  courseMaterialDownloadFilename: string | null;

  @Column("bit", {
    name: "is_staff_only",
    nullable: true,
    comment: "스태프 (법정 의무 교육 들은 회원) 만 볼 수 있는 강의",
    default: () => "'b'0''",
  })
  isStaffOnly: boolean | null;

  @Column("int", {
    name: "priority",
    nullable: true,
    comment: "노출 우선순위",
    default: () => "'1000'",
  })
  priority: number | null;

  @OneToMany(
    () => LectureCourseBookmark,
    (lectureCourseBookmark) => lectureCourseBookmark.course
  )
  lectureCourseBookmarks: LectureCourseBookmark[];

  @ManyToOne(() => CommonCode, (commonCode) => commonCode.lectureCourses, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "common_code_id", referencedColumnName: "codeId" }])
  commonCode: CommonCode;

  @ManyToOne(
    () => CommonCodeSecondary,
    (commonCodeSecondary) => commonCodeSecondary.lectureCourses,
    { onDelete: "RESTRICT", onUpdate: "RESTRICT" }
  )
  @JoinColumn([
    {
      name: "common_code_secondary_id",
      referencedColumnName: "secondaryCodeId",
    },
  ])
  commonCodeSecondary: CommonCodeSecondary;

  @OneToMany(() => Lecture, (lecture) => lecture.course)
  lectures: Lecture[];
}
