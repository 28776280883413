import ResumeTable from "../../components/board/ResumeTable";

const ResumePage = () => {
  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">인재정보 게시판</div>
      </div>
      <ResumeTable />
    </div>
  );
};

export default ResumePage;
