import { useState } from "react";
import OralHealthClassTable from "../../components/board/OralHealthClassTable";
import FileInput from "../../components/form/FileInput";
import Popup from "../../components/Popup";
import { EntityService } from "../../services/entity.service";
import { LectureCourse } from "../../entities/entities/LectureCourse";
import axios from "axios";
import { getHost } from "../..";

const entityService = new EntityService();

const OralHealthClassPage = () => {
  const [writePopup, setWritePopup] = useState<boolean>(false);

  return (
    <div className="inner page">
      {writePopup && (
        <WritePopup
          close={() => setWritePopup(false)}
          modify={(writePopup as any)?.course_id ? writePopup : false}
        />
      )}
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <div className="page-tit mr-5">구강보건교육</div>
          <div className="page-sub-tit">코스 목록</div>
        </div>
        <button className="blue" onClick={() => setWritePopup(true)}>
          등록하기
        </button>
      </div>
      <OralHealthClassTable setWritePopup={setWritePopup} />
    </div>
  );
};

export default OralHealthClassPage;

function WritePopup({ close, modify }) {
  const [creator, setCreator] = useState<string>(modify?.creator);
  const [title, setTitle] = useState<string>(modify?.title);
  const [thumbnail, setThumbnail] = useState<any>(
    modify?.image_filename
  );
  const [description, setDescription] = useState<string>(
    modify?.description || ""
  );
  const [priority, setPriority] = useState<number>(modify?.priority);

  const submit = async () => {

    if (!creator) {
      alert("제작자를 입력하세요.");
      return false;
    }

    if (!title) {
      alert("제목을 입력하세요.");
      return false;
    }

    if (!priority) {
      alert("노출 순서를 정해주세요.");
      return false;
    }

    try {
      if (modify) {
        const { finish_count, ...res } = modify;
        await entityService.update(LectureCourse, modify.course_id, {
          ...res,
          creator,
          title,
          priority,
          imageFilename: thumbnail,
          description,
          updDate: new Date(),
        });
        return;
      }
      await entityService.create(LectureCourse, {
        creator,
        title,
        description,
        priority,
        score: 0,
        recommendFlag: false,
        lectureType: 3,
        imageFilename: thumbnail,
        startDate: new Date(),
        regDate: new Date(),
      });
    } catch {
      alert("에러가 발생하였습니다");
    }
  };

  return (
    <Popup
      title="구강보건교육 코스등록"
      submit={async () => {
        try {
          await submit();
          alert("등록되었습니다");
          close();
          window.location.reload();
        } catch {
          alert("에러가 발생하였습니다.")
        }
      }}
      close={close}
      confirmTxt="등록"
    >
      <div className="space-y-5">
        <div className="flex justify-between items-center">
          <div className="w-[15%]">제작자</div>
          <input
            className="text-input"
            type="text"
            value={creator}
            onChange={(e) => setCreator(e.target.value)}
            placeholder="텍스트를 입력하세요"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">제목</div>
          <input
            className="text-input"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="텍스트를 입력하세요"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">썸네일</div>
          <div className="w-[85%]">
            <FileInput
              selectFile={async (f) => {
                const res = await axios.post(
                  `${getHost()}/admin/file/upload`,
                  { file: f[0] },
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                setThumbnail(res.data.data.file_name);
              }}
              file={thumbnail}
            />
          </div>
        </div>
        <div className="text-sm text-end text-[#aaa] relative -top-3">
          권장사이즈: 220 x 140px
        </div>
        <div className="flex justify-between items-top">
          <div className="w-[15%]">설명</div>
          <textarea
            className="shadow-custom w-[85%] h-36 rounded-sm px-4 py-2"
            placeholder="텍스트를 입력하세요"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">노출순서</div>
          <input
            className="text-input"
            type="number"
            placeholder="0"
            style={{ width: "85%" }}
            value={priority}
            onChange={(e) => setPriority(parseInt(e.target.value))}
          />
        </div>
      </div>
    </Popup>
  );
}
