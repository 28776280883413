import DataTable from "../../components/board/DataTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { EntityService } from "../../services/entity.service";
import { Reference } from "../../entities/entities/Reference";

const DataPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<[Reference[], number] | null>(null);
  const entityService = new EntityService();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchMaterials(page, 20);
      setData(res);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, navigate]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/info/admin?page=${selected}`);
  };

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">자료실</div>
        <Link to="/board/datawrite">
          <button className="blue">등록하기</button>
        </Link>
      </div>
      <DataTable data={data} onPageClick={onPageClick} page={page} />
    </div>
  );
};

export default DataPage;
