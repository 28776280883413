import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TermsTable from "../../components/info/TermsTable";
import { Policy } from "../../entities/entities/Policy";
import { EntityService, PolicyType } from "../../services/entity.service";

const PolicyPage = () => {
  const entityService = new EntityService();
  const [policyPageData, setpolicyPageData] = useState<
    (number | Policy[])[] | null
  >(null);
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const [checkItems, setCheckItems] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchPolicies(page, 20, PolicyType.PRIVACY_INFO_COLLECTION_AGREEMENT, 3);
      setpolicyPageData(res);
    })();
  }, [page]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/info/terms?page=${selected}`);
  };

  const deleteList = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
    try {

      const checkItemFirstId = checkItems[0];
      const samplePolicy = await fetchDetailEntity(Policy, { policyId: checkItemFirstId });

      await entityService.deleteMultiples(Policy, "policyId", checkItems);

      if (samplePolicy.title === '이용약관' || samplePolicy.title === '개인정보수집및동의') {
        await entityService.enableRequireFlagForLastPolicy(samplePolicy.title, samplePolicy.type, samplePolicy.category);
      }

      alert("삭제가 완료되었습니다.");
      window.location.reload();
    } catch (e) {
      console.log(e);
      alert('에러가 발생했습니다.');
    }
  }
  };

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        {location.pathname === "/info/agree" ? (
          <div className="page-tit">개인정보수집및동의</div>
        ) : (
          <div className="page-tit">개인정보처리방침</div>
        )}

        <div className="space-x-2">
          <Link to="/info/policywrite">
            <button className="blue">등록하기</button>
          </Link>
          <button onClick={deleteList}>선택삭제</button>
        </div>
      </div>
      {policyPageData && (
        <TermsTable
          state={policyPageData}
          onPageClick={onPageClick}
          page={page}
          checkItems={checkItems}
          setCheckItems={setCheckItems}
        />
      )}
    </div>
  );
};

export default PolicyPage;
