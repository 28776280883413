import { Column, Entity } from "typeorm";

@Entity("recommend_keyword", { schema: "dentin" })
export class RecommendKeyword {
  @Column("tinyint", {
    primary: true,
    name: "recommend_type",
    comment: "1: 채용 공고, 2: 이력서",
  })
  recommendType: number;

  @Column("varchar", {
    primary: true,
    name: "keyword",
    comment: "추천 키워드",
    length: 64,
  })
  keyword: string;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;
}
