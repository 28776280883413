import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { EducationFaq } from "../../entities/entities/EducationFaq";
import { Faq } from "../../entities/entities/Faq";
import { EntityService } from "../../services/entity.service";
import { toYYYYMMDD } from "../../services/utils/repository-util.service";

const FaqTable = ({
  state,
  onPageClick,
  page,
}: {
  state: [EducationFaq[], number];
  onPageClick: (data: { selected: number }) => void;
  page: number;
}) => {
  const data = state && state[0];
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const type = param.get("type") || "recruit";

  const deleteClick = async (id) => {
    const entityService = new EntityService();
    const deleteType = type === "recruit" ? Faq : EducationFaq;
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        await entityService.delete(deleteType, id);
        alert("삭제가 완료되었습니다.");
        window.location.reload();
      } catch (e) {
        console.log(e);
        alert("에러가 발생했습니다.");
      }
    }
    
  };

  return (
    <>
      <div className="table">
        <div className="row header">
          <div className="w-3/12">질문</div>
          <div className="w-7/12">답변</div>
          <div className="w-1/12">등록일</div>
          <div className="w-1/12"></div>
        </div>
        {data?.map((item) => {
          return (
            <details>
              <summary className="row h-[34px] overflow-hidden">
                <div className="w-3/12">
                  <div className="content">{item.question}</div>
                </div>
                <div className="w-7/12">
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  ></div>
                </div>
                <div className="w-1/12">
                  {toYYYYMMDD(new Date(item.regDate))}
                </div>
                <div className="w-1/12 justify-center">
                  <i className="ii ii-chevron-down"></i>
                </div>
              </summary>
              <div className="row detail">
                <div className="text-lg text-[#1F1F1F]">
                  <span className="text-main">Q.</span>
                  {item.question}
                </div>
                <div>
                  <span>A.</span>
                  <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                </div>
                <div className="flex w-full justify-end">
                  <button onClick={() => deleteClick(item.faqId)}>삭제</button>
                  <button
                    className="dark"
                    onClick={() =>
                      navigate("/communication/faqwrite", {
                        state: { ...item, type: type },
                      })
                    }
                  >
                    수정
                  </button>
                </div>
              </div>
            </details>
          );
        })}
      </div>
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={state && state[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
    </>
  );
};

export default FaqTable;
