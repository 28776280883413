const NewVideo = ({ data }) => {
  return (
    <div className="w-[480px]">
      <div className="flex items-end justify-between mb-3">
        <div className="text-[#333] text-[20px] font-bold">최신 동영상</div>
        <a
          href="https://dent-in.co.kr/lecture"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="text-[#666] font-semibold text-[15px]">더보기 〉</div>
        </a>
      </div>
      <div className="bg-[#F8F8F8] border-t-[1.5px]  border-[#333] h-64">
        <div className="flex items-center justify-evenly border-b-[1.5px] border-[#CFCFCF] py-2">
          <div className="w-[15%] flex items-center justify-center">최신순</div>

          <div className="w-[65%]">코스명</div>
          <div className="w-[15%] flex items-center justify-center">
            이수자 수
          </div>
        </div>
        {data?.map((i, idx) => {
          return (
            <div key={idx} className="flex items-center justify-evenly py-2">
              <div className="w-[15%] flex items-center justify-center">
                {idx + 1}
              </div>
              <div className="w-[65%] truncate">{i.creator}</div>
              <div className="w-[15%] flex items-center justify-center">
                {i.finishCount}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewVideo;
