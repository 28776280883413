import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Popup from "../../components/Popup";
import { ClinicMember } from "../../entities/entities/ClinicMember";
import { EntityService } from "../../services/entity.service";

const entityService = new EntityService();

export default function ClinicMemberPage() {
  const [banPopup, setBanPopup] = useState<ClinicMember | null>(null);
  const [activePopup, setActivePopup] = useState<ClinicMember | null>(null);
  const [memberList, setMemberList] = useState<[ClinicMember[], number] | null>(
    null
  );
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const searchParam = param.get("searchParam") || "";
  const [searchKeyword, setSearchKeyword] = useState<string>(searchParam || "");

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchHospitalUsers(
        page,
        20,
        searchParam ? searchParam : null
      );
      setMemberList(res);
    })();
  }, [page, searchParam]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(
      `/member/clinic?page=${selected}${
        searchKeyword ? `&searchParam=${searchKeyword}` : ""
      }`
    );
    window.scrollTo(0, 0);
  };

  const search = async (
    e: React.ChangeEvent<HTMLSelectElement> | React.FormEvent<HTMLFormElement>,
    keyword: string
  ) => {
    e.preventDefault();
    navigate(
      `/member/clinic?page=${1}${keyword ? `&searchParam=${keyword}` : ""}`
    );
  };

  if (!memberList) return <></>;
  return (
    <div className="inner page">
      <div className="page-tit">회원정보 관리</div>
      <div className="flex mt-8">
        <button className="blue">병원회원</button>
        <button className="white" onClick={() => navigate("/member/personal")}>
          개인회원
        </button>
      </div>
      <form onSubmit={(e) => search(e, searchKeyword)}>
        <input
          className="text-input mt-8"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          style={{ width: 150 }}
          placeholder="검색하기..."
          type="text"
        />
      </form>
      <div className="table">
        <div
          className="row header"
          style={{ background: "#F4F6F8", padding: "15px 0" }}
        >
          <div className="w-[10%] justify-center text-[#637381]">이름</div>
          <div className="w-[10%] justify-center text-[#637381]">병원유형</div>
          <div className="w-[15%] justify-center text-[#637381]">전화번호</div>
          <div className="w-[15%] justify-center text-[#637381]">이메일</div>
          <div className="w-[15%] justify-center text-[#637381]">아이디</div>
          <div className="w-[15%] justify-center text-[#637381]">사업자등록번호</div>
          <div className="w-[15%] justify-center text-[#637381]">가입일</div>
          <div className="w-[15%] justify-center text-[#637381]">
            최종접속일
          </div>
          <div className="w-[25%] justify-center text-[#637381]">관리</div>
        </div>
        {memberList[0].map((member, idx) => (
          <>
            <div
              className="row"
              key={idx}
              style={{ padding: "10px 0", borderBottom: "none" }}
            >
              <div className="w-[10%] justify-center">{member.clinicName}</div>
              <div className="w-[10%] justify-center text-main">
                {member.clinicType}
              </div>
              <div className="w-[15%] justify-center">{member.phoneNumber}</div>
              <div className="w-[15%] justify-center">{member.email}</div>
              <div className="w-[15%] justify-center">{member.memberId}</div>
              <div className="w-[15%] justify-center">{member.bizRegNumber}</div>
              <div className="w-[15%] justify-center">
                {member.regDate
                  ? dayjs(member.regDate).format("YYYY-MM-DD HH:mm")
                  : ""}
              </div>
              <div className="w-[15%] justify-center">
                {member.lastLoginDate
                  ? dayjs(member.lastLoginDate).format("YYYY-MM-DD HH:mm")
                  : ""}
              </div>
              <div className="w-[25%] justify-center">
                <button
                  className="bg-main mr-3"
                  onClick={() =>
                    navigate(
                      `/communication/qna?user_id=${member.memberId}&user_type=2&user_name=${member.repName}`
                    )
                  }
                >
                  메세지
                </button>

                {member.activeFlag ? (
                  <button onClick={() => setBanPopup(member)}>회원정지</button>
                ) : (
                  <button
                    onClick={() => setActivePopup(member)}
                    className="white"
                  >
                    정지해제
                  </button>
                )}
                <Link to={`/member/clinic/${member.memberId}`} className="ml-3">
                  <button className="dark">상세</button>
                </Link>
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={memberList[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
      {banPopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.update(ClinicMember, banPopup.memberId, {
                ...banPopup,
                activeFlag: false,
              });
              setBanPopup(null);
              setMemberList((cur) => {
                if (!cur) return cur;
                cur[0] = cur[0].map((item) =>
                  item.memberId === banPopup.memberId
                    ? { ...item, activeFlag: false }
                    : item
                );
                return [...cur];
              });
              alert("정지되었습니다");
            } catch {
              alert("에러 발생");
            }
          }}
          close={() => setBanPopup(null)}
        >
          <div className="flex justify-center font-semibold">
            회원정지 하시겠습니까?
          </div>
        </Popup>
      )}
      {activePopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.update(ClinicMember, activePopup.memberId, {
                ...activePopup,
                activeFlag: true,
              });
              setActivePopup(null);
              setMemberList((cur) => {
                if (!cur) return cur;
                cur[0] = cur[0].map((item) =>
                  item.memberId === activePopup.memberId
                    ? { ...item, activeFlag: true }
                    : item
                );
                return [...cur];
              });
              alert("해제되었습니다");
            } catch {
              alert("에러 발생");
            }
          }}
          close={() => setActivePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            정지해제 하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
}
