import { Link, useLocation } from "react-router-dom";
import { MAIN_CATEGORY, SUB_CATEGORY } from "../constants";
import { cls } from "../utils/class";

export default function Header() {
  const location = useLocation();

  const mainCate = MAIN_CATEGORY.find((cate) =>
    location.pathname.startsWith(`/${cate.value}`)
  );

  const hasSubCate = mainCate && SUB_CATEGORY[mainCate.value];

  return (
    <>
      <div
        className={cls(
          hasSubCate ? "mb-16" : "",
          "header-shadow h-16 flex items-center"
        )}
      >
        <div className="inner relative flex items-center w-full h-9">
          <Link to="/" className="cursor-pointer h-full flex mr-12">
            <div className="w-20 self-center">
              <img src="/logo.svg" className="w-20" alt="logo" />
            </div>
            <div className="text-main ml-2 self-end">ADMIN</div>
          </Link>
          <div className="flex justify-around text-gray-700 space-x-10 pt-1">
            <div className="flex justify-center">
              <Link
                to="/"
                className={cls(
                  !mainCate ? "text-main" : "",
                  "block whitespace-nowrap hover:text-main"
                )}
              >
                홈
              </Link>
            </div>
            {MAIN_CATEGORY.map((cate, idx) => (
              <div className="flex justify-center relative" key={idx}>
                <Link
                  to={`/${cate.value}`}
                  className={cls(
                    mainCate?.value === cate.value ? "text-main" : "",
                    "block whitespace-nowrap hover:text-main"
                  )}
                >
                  {cate.label}
                </Link>
                {hasSubCate && cate.value === mainCate.value && (
                  <>
                    <div
                      className={cls(
                        cate.value === "board" ? "-left-72" : "left-0",
                        "absolute top-11 flex pt-4 pb-3 space-x-14"
                      )}
                    >
                      {SUB_CATEGORY[mainCate.value].map((sub, idx) => (
                        <div key={idx}>
                          <Link
                            to={`/${mainCate.value}/${sub.value}`}
                            className={cls(
                              location.pathname.includes(sub.value) ||
                                (location.pathname === "/info" &&
                                  sub.value === "admin") ||
                                (location.pathname === "/communication" &&
                                  sub.value === "faq") ||
                                (location.pathname === "/board" &&
                                  sub.value === "category") ||
                                (location.pathname === "/statistics" &&
                                  sub.value === "daily") ||
                                (location.pathname === "/intern" &&
                                  sub.value === "posting")
                                ? "text-main"
                                : "",
                              "block whitespace-nowrap"
                            )}
                          >
                            {sub.label}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <a
            href="https://dent-in.co.kr"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-main text-white py-2 min-w-[150px] cursor-pointer rounded-full flex items-center whitespace-nowrap justify-center ml-10"
          >
            치과인 바로가기
          </a>
          <div
            className="bg-white text-black py-2 min-w-[100px] border-2 cursor-pointer rounded-full flex items-center whitespace-nowrap justify-center ml-4"
            onClick={() => {
              sessionStorage.clear();
              window.location.reload();
            }}
          >
            로그아웃
          </div>
          {hasSubCate && (
            <div className="absolute top-[6.5rem] border-b-[1px] w-full border-[#F5F5F5]"></div>
          )}
        </div>
      </div>
    </>
  );
}
