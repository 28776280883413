import React, { Dispatch, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { LandingPageData } from "../../interfaces/landing-page-data.interface";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";
import { ko } from "date-fns/esm/locale";

const Daily = ({
  landingPageData,
  dates,
  setDates,
}: {
  landingPageData: LandingPageData | undefined;
  dates: string | number | Date;
  setDates: Dispatch<React.SetStateAction<Date>>;
}) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    dayjs(dates).format("YYYY.MM.DD") === dayjs(new Date()).format("YYYY.MM.DD")
      ? setDisabled(true)
      : setDisabled(false);
  }, [dates]);

  const addDay = () => {
    const result = new Date(dates);
    result.setDate(result.getDate() + 1);
    setDates(result);
  };
  const substractDay = () => {
    const result = new Date(dates);
    result.setDate(result.getDate() - 1);
    setDates(result);
  };

  const ExampleCustomInput = forwardRef((props: any, ref) => {
    return (
      <div
        className="example-custom-input"
        style={{ cursor: "pointer" }}
        {...props}
        ref={ref}
      >
        <i className="ii-calendar-event-outline"></i>
      </div>
    );
  });
  
const inputRef = useRef(null);

return (
  <div>
    <div className="flex items-center space-x-64">
      <div className="page-tit mb-9">일간현황</div>
      <div className="flex items-center space-x-4 text-[#333] font-medium text-[25px]">
        <div className="cursor-pointer" onClick={substractDay}>
          <i className="ii-chevron-left"></i>
        </div>
        <div>{dayjs(dates).format("YYYY.MM.DD")}</div>
        {!disabled && (
          <div className="cursor-pointer" onClick={addDay}>
            <i className="ii-chevron-right"></i>
          </div>
        )}

        <DatePicker
          selected={dates}
          locale={ko}
          maxDate={new Date()}
          onChange={(date) => setDates(date)}
          customInput={<ExampleCustomInput inputref={inputRef} />}
        />
      </div>
    </div>

    <div className="shadow-custom h-56 rounded-sm py-4 flex items-center mb-11 w-[1050px]">
      <div>
        <div className="px-[45px] border-r border-[#CFCFCF] w-[350px]">
          <div className="text-[#6BC38E] text-[23px] font-bold">
            오늘 순 방문자 수
          </div>
          <h1 className="text-[#333] font-bold text-[45px]">
            {landingPageData?.todayVisitor.total || 0} 명
          </h1>
          <div className="text-lg font-semibold text-[#333]">
            재방문율
            <span className="text-[#6BC38E] ml-2">
              {Math.round(landingPageData?.todayVisitor?.revisitRatio || 0)}%
            </span>
          </div>
          <div className="text-[#333] font-medium text-[13px] mt-8">
            {dayjs(dates).format("YYYY.MM.DD HH:mm:ss")} 기준
          </div>
        </div>
      </div>

      <div className="border-r border-[#999] w-[350px] px-[45px]">
        <div className="text-main text-[23px] font-bold ">오늘 작성글 수</div>
        <h1 className="text-[#333] font-bold text-[45px]">
          총 {landingPageData?.todayBoardCount.total} 개
        </h1>
        <div className="border-[1px] border-[#999] rounded-md px-2 py-1 w-68">
          <div className="flex items-center border-b border-[#999]">
            <div className="flex items-center justify-between pr-3 w-32 border-r border-[#999]">
              <div className="text-[#666] text-xs font-semibold w-12">
                채용공고
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayBoardCount.recruits}개
              </div>
            </div>
            <div className="flex items-center justify-between w-32 pl-3">
              <div className="text-[#666] text-xs font-semibold w-12">
                인재정보
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayBoardCount.humanResources}개
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-between pr-3 w-32 border-r border-[#999]">
              <div className="text-[#666] text-xs font-semibold w-11">
                동영상
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayBoardCount.videos}개
              </div>
            </div>
            <div className="flex items-center space-x-3 justify-between w-32 pl-3">
              <div className="text-[#666] text-xs font-semibold w-12">
                실습치과
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayBoardCount.interns}개
              </div>
            </div>
          </div>
        </div>
        <div className="text-[#333] font-medium text-[13px] mt-2">
          {dayjs(dates).format("YYYY.MM.DD HH:mm:ss")} 기준
        </div>
      </div>

      <div className="w-[350px] px-[45px]">
        <div className="text-main text-[23px] font-bold">오늘 회원현황</div>
        <h1 className="text-[#333] font-bold text-[45px]">
          총 {landingPageData?.todayMemberCount.total} 명
        </h1>
        <div className="border-[1px] border-[#999] rounded-md px-2 py-1 w-68">
          <div className="flex items-center border-b border-[#999]">
            <div className="flex items-center justify-between pr-3 w-32 border-r border-[#999]">
              <div className="text-[#666] text-xs font-semibold w-13">
                병원 신규가입
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayMemberCount.newbieHospitalCount}명
              </div>
            </div>
            <div className="flex items-center justify-between w-32 pl-3">
              <div className="text-[#666] text-xs font-semibold w-13">
                개인 신규가입
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayMemberCount.newbiewCount}명
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-between pr-3 w-32 border-r border-[#999]">
              <div className="text-[#666] text-xs font-semibold w-11">
                회원탈퇴
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayMemberCount.withdrawCount}명
              </div>
            </div>
            <div className="flex items-center justify-between w-32 pl-3">
              <div className="text-[#666] text-xs font-semibold w-12">
                회원중지
              </div>
              <div className="text-sm font-bold text-main">
                {landingPageData?.todayMemberCount.suspended}명
              </div>
            </div>
          </div>
        </div>
        <div className="text-[#333] font-medium text-[13px] mt-2">
          {dayjs(dates).format("YYYY.MM.DD HH:mm:ss")} 기준
        </div>
      </div>
    </div>
  </div>
);
};

export default Daily;
