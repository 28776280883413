import EditorSelectForm from "../../components/form/EditorSelectForm";

const NoticeWrite = () => {
  return (
    <div>
      <EditorSelectForm title="공지사항 등록" />
    </div>
  );
};

export default NoticeWrite;
