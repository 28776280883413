import axios from "axios";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getHost } from "../..";
import DentinClassDetailTable from "../../components/board/DentinClassDetailTable";
import FileInput from "../../components/form/FileInput";
import Popup from "../../components/Popup";
import { Lecture } from "../../entities/entities/Lecture";
import { EntityService } from "../../services/entity.service";

const entityService = new EntityService();

const LectureDetail = ({ title }) => {
  const [writePopup, setWritePopup] = useState<Lecture | boolean>(false);
  const location = useLocation();

  return (
    <div className="inner page">
      {writePopup && (
        <WritePopup
          close={() => setWritePopup(false)}
          modify={(writePopup as any)?.course_id ? writePopup : false}
        />
      )}
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <div className="page-detail-tit">
            {title}
            <i className="ii ii-chevron-right"></i>
          </div>
          <div className="page-tit mr-5">{location.state}</div>
        </div>
        <button className="blue" onClick={() => setWritePopup(true)}>
          등록하기
        </button>
      </div>
      <DentinClassDetailTable setWritePopup={setWritePopup} />
    </div>
  );
};

export default LectureDetail;

function WritePopup({ close, modify }) {
  const [creator, setCreator] = useState<string>(modify?.creator);
  const [name, setName] = useState<string>(modify?.name);
  const [description, setDescription] = useState<string>(
    modify?.description || ""
  );
  const [videoId, setVideoId] = useState<string>(modify?.video_id || "");
  const [playTime, setPlayTime] = useState<string>(modify?.play_time);
  const [thumbnail, setThumbnail] = useState<any>(modify?.image_filename);
  const [seq, setSeq] = useState<string>(modify?.seq || "");
  const [file, setFile] = useState<{ link: string; filename: string }>(
    modify?.material_download_link
      ? {
          link: modify?.material_download_link,
          filename: modify?.material_download_filename,
        }
      : { link: "", filename: "" }
  );
  const courseId = useParams().id || "1";

  const submit = async () => {
    if (!creator) {
      alert("제작자를 입력해주세요");
      return;
    }
    if (!name) {
      alert("제목을 입력해주세요");
      return;
    }
    if (!courseId.length) {
      alert("영상 ID를 입력해주세요");
      return;
    }
    if (!seq.length) {
      alert("노출순서를 입력해주세요");
      return;
    }
    try {
      if (modify) {
        await entityService.update(Lecture, modify.lecture_id, {
          creator,
          description,
          name,
          videoId,
          playTime: Number(playTime) || 0,
          courseId,
          seq: Number(seq),
          imageFilename: thumbnail,
          materialDownloadLink: file.link,
          materialDownloadFilename: file.filename,
        });
      } else {
        await entityService.create(Lecture, {
          creator,
          description,
          name,
          videoId,
          playTime: Number(playTime) || 0,
          courseId,
          seq: Number(seq),
          imageFilename: thumbnail,
          materialDownloadLink: file.link,
          materialDownloadFilename: file.filename,
        });
      }
      close();
      alert("등록되었습니다");
      window.location.reload();
    } catch {
      alert("에러가 발생하였습니다");
    }
  };

  return (
    <Popup title="강의 등록" submit={submit} close={close} confirmTxt="등록">
      <div className="space-y-5">
        <div className="flex justify-between items-center">
          <div className="w-[15%]">제작자</div>
          <input
            className="text-input"
            type="text"
            value={creator}
            onChange={(e) => setCreator(e.target.value)}
            placeholder="텍스트를 입력하세요"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">제목</div>
          <input
            className="text-input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="텍스트를 입력하세요"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">썸네일</div>
          <div className="w-[85%]">
            <FileInput
              selectFile={async (f) => {
                const res = await axios.post(
                  `${getHost()}/admin/file/upload`,
                  { file: f[0] },
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                setThumbnail(res.data.data.file_name);
              }}
              file={thumbnail}
            />
          </div>
        </div>
        <div className="text-sm text-end text-[#aaa] relative -top-3">
          권장사이즈: 220 x 140px
        </div>
        <div className="flex justify-between items-top">
          <div className="w-[15%]">설명</div>
          <textarea
            className="shadow-custom w-[85%] h-36 rounded-sm px-4 py-2"
            placeholder="텍스트를 입력하세요"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">영상 ID</div>
          <input
            className="text-input"
            type="text"
            value={videoId}
            onChange={(e) => setVideoId(e.target.value)}
            placeholder="영상 링크의 ID를 입력하세요"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">교육시간</div>
          <input
            className="text-input"
            type="number"
            value={playTime}
            onChange={(e) => {
              setPlayTime(e.target.value);
            }}
            placeholder="숫자를 입력하세요. (초 단위)"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">첨부파일</div>
          <div className="w-[85%]">
            <FileInput
              selectFile={async (f) => {
                const res = await axios.post(
                  `${getHost()}/admin/file/upload`,
                  { file: f[0] },
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                setFile({
                  filename: res.data.data.upload_file_name,
                  link: res.data.data.file_name,
                });
              }}
              file={file.filename}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">노출순서</div>
          <input
            className="text-input"
            type="number"
            value={seq}
            onChange={(e) => setSeq(e.target.value)}
            placeholder="노출순서는 기존과 중복되면 등록되지 않습니다. (숫자만 입력)"
            style={{ width: "85%" }}
          />
        </div>
      </div>
    </Popup>
  );
}
