import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { Member } from "./Member";
import { ClinicMember } from "./ClinicMember";

@Index("member_id", ["memberId"], {})
@Index("clinic_member_id", ["clinicMemberId"], {})
@Entity("admin_chat_message", { schema: "dentin" })
export class AdminChatMessage {
  @PrimaryGeneratedColumn({ type: "int", name: "id" })
  id: number;

  @Column("bit", {
    name: "from_admin",
    nullable: true,
    default: () => "'b'0''",
  })
  fromAdmin: boolean | null;

  @Column("tinyint", { name: "member_type", nullable: true })
  memberType: number | null;

  @Column("varchar", { name: "member_id", nullable: true, length: 32 })
  memberId: string | null;

  @Column("varchar", { name: "clinic_member_id", nullable: true, length: 32 })
  clinicMemberId: string | null;

  @Column("text", { name: "message", nullable: true })
  message: string | null;

  @Column("datetime", {
    name: "reg_date",
    comment: "등록 일시",
    default: () => "CURRENT_TIMESTAMP",
  })
  regDate: Date;

  @Column("bit", {
    name: "read_flag",
    comment: "읽음 여부",
    default: () => "'b'0''",
  })
  readFlag: boolean;

  @Column("bit", {
    name: "check_flag",
    nullable: true,
    default: () => "'b'0''",
  })
  checkFlag: boolean | null;

  @ManyToOne(() => Member, (member) => member.adminChatMessages, {
    onDelete: "SET NULL",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: Member;

  @ManyToOne(
    () => ClinicMember,
    (clinicMember) => clinicMember.adminChatMessages,
    { onDelete: "SET NULL", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "clinic_member_id", referencedColumnName: "memberId" }])
  clinicMember: ClinicMember;
}
