import { useNavigate } from "react-router-dom";
import { EntityService } from "../../services/entity.service";
import { CommonCode } from "../../entities/entities/CommonCode";
import { useEffect, useState } from "react";
import Popup from "../../components/Popup";
import { CommonCodeSecondary } from "../../entities/entities/CommonCodeSecondary";

const entityService = new EntityService();

export default function Category() {
  const navigate = useNavigate();
  const [deleteMainCate, setDeleteMainCate] = useState<CommonCode | null>(null);
  const [deleteSubCate, setDeleteSubCate] =
    useState<CommonCodeSecondary | null>(null);
  const [code, setCode] = useState<CommonCode[] | null>(null);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchCategories();
      setCode(res);
    })();
  }, []);

  if (!code) return <></>;
  return (
    <div className="inner page">
      <div className="flex justify-between items-end">
        <div className="flex items-end">
          <div className="page-tit mr-5">카테고리 관리</div>
          <div className="page-sub-tit">치과인 강좌</div>
        </div>
        <button
          className="blue"
          onClick={() => navigate("/board/category/write")}
        >
          등록하기
        </button>
      </div>
      <div className="table">
        <div className="row header">
          <div className="w-10/12 ml-5">카테고리명</div>
          <div className="w-1/12 justify-center">수정</div>
          <div className="w-1/12 justify-center">삭제</div>
        </div>
        {code.map((cate, idx) => (
          <>
            <div className="row" key={idx}>
              <div className="w-10/12">
                <div className="ml-5 text-input my-2" style={{ width: "80%" }}>
                  {cate.codeName}
                </div>
              </div>
              <div className="w-1/12 justify-center">
                <button
                  className="dark"
                  onClick={() =>
                    navigate("/board/category/write", { state: cate })
                  }
                >
                  수정
                </button>
              </div>
              <div className="w-1/12 justify-center">
                <button onClick={() => setDeleteMainCate(cate)}>삭제</button>
              </div>
            </div>
            {cate.commonCodeSecondaries.map((c) => (
              <div className="row bg-[#F8F8F8]">
                <div className="w-10/12">
                  <div className="ml-5 text-2xl">ㄴ</div>
                  <div
                    className="ml-5 text-input my-2"
                    style={{ width: "80%" }}
                  >
                    {c.secondaryCodeName}
                  </div>
                </div>
                <div className="w-1/12 justify-center">
                  <button
                    className="dark"
                    onClick={() =>
                      navigate("/board/category/write", { state: c })
                    }
                  >
                    수정
                  </button>
                </div>
                <div className="w-1/12 justify-center">
                  <button onClick={() => setDeleteSubCate(c)}>삭제</button>
                </div>
              </div>
            ))}
          </>
        ))}
      </div>
      {deleteMainCate && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteCategory(
                parseInt(deleteMainCate.codeId)
              );
              setDeleteMainCate(null);
              alert("삭제되었습니다");
              window.location.reload();
            } catch {
              alert("에러 발생");
            }
          }}
          close={() => setDeleteMainCate(null)}
        >
          <div className="flex justify-center font-semibold">
            {deleteMainCate.codeName} 카테고리를 삭제하시겠습니까?
          </div>
        </Popup>
      )}
      {deleteSubCate && (
        <Popup
          submit={async () => {
            try {
              await entityService.delete(
                CommonCodeSecondary,
                deleteSubCate.secondaryCodeId
              );
              setDeleteSubCate(null);
              window.location.reload();
              alert("삭제되었습니다");
            } catch {
              alert("에러 발생");
            }
          }}
          close={() => setDeleteSubCate(null)}
        >
          <div className="flex justify-center font-semibold">
            {deleteSubCate.secondaryCodeName} 카테고리를 삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
}
