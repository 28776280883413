import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { Lecture } from "../../entities/entities/Lecture";
import { EntityService } from "../../services/entity.service";
import Popup from "../Popup";
import dayjs from "dayjs";

const entityService = new EntityService();

const DutyClassTable = ({ setWritePopup }) => {
  const [dutyClassList, setDutyClassList] = useState<
    [(Lecture & { students_count: number } )[], number] | null
  >(null);
  const [deletePopup, setDeletePopup] = useState<
    (Lecture & { students_count: number }) | null
  >(null);
  const [startDate, setStartDate] =  useState<string | null>(null)
  const [endDate, setEndDate] =  useState<string | null>(null)
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const now = new Date();

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/board/duty-class?page=${selected}`);
    window.scrollTo(0, 0);
  };
  const setDateAction = (el) =>{

    let btn = document.querySelectorAll('.'+el.classList.value)

    btn.forEach((el)=>{
      el.classList.remove('text-main')
    })
    // dutyClassList를 업데이트
    // setDutyClassList((prevDutyClassList) =>
    //
    //   prevDutyClassList!.map((v, i) => {
    //     if (i === index) {
    //       return {
    //         ...v,
    //         excel_start_date: dayjs(new Date(now.getFullYear(), now.getMonth() - month, 1)).format('YYYY-MM-DD'),
    //         excel_end_date: dayjs(new Date(now.getFullYear(), now.getMonth(), 0)).format('YYYY-MM-DD'),
    //       };
    //     } else {
    //       return v;
    //     }
    //   })
    // );

    el.classList.add('text-main');
  }
  useEffect(() => {
    (async () => {
      const res = await entityService.fetchLegalLectureList(page);
      console.log(res)
      setDutyClassList(res);
    })();
  }, [page]);

  const exportExcel = async (item, idx) =>{
    let btn = document.querySelectorAll(`.range_btn_${idx}`)
    let ranges = document.querySelectorAll(`[name="range_${idx}[]"]`)
    let btnMonth: string | null = '';
    let rangeUse:boolean = true;
    btn.forEach((e,i)=>{
       if(e.classList.value.includes('text-main')) {
         btnMonth = e.getAttribute('data-month')
         rangeUse = false;
       }
    })
    ranges.forEach((e,i)=>{
      if((e as HTMLInputElement).value == '') {
        rangeUse = false;
      }
    })
    if(btnMonth) {
      const list = await entityService.fetchLecturesStudentList(
        parseInt((item as any).lecture_id),
        false,
        dayjs(new Date(now.getFullYear(), now.getMonth() - Number(btnMonth), now.getDate())).format('YYYY-MM-DD'),
        dayjs(new Date(now)).format('YYYY-MM-DD')
      );

      await entityService.downloadJsonArrayToCSV(item.name, list);
    }
    if(rangeUse) {
      let startDate = (ranges[0] as HTMLInputElement).value
      let endDate = (ranges[1] as HTMLInputElement).value
      if(new Date(startDate) > new Date(endDate)) {
        alert('시작일을 확인해주세요.')
        return;
      }
      const list = await entityService.fetchLecturesStudentList(
        parseInt((item as any).lecture_id),
        endDate ? false : true,
        startDate,
        endDate,
      );

      await entityService.downloadJsonArrayToCSV(item.name, list);
    }
  }
  const hideBtnAction = (idx) => {
    let btn = document.querySelectorAll(`.range_btn_${idx}`)

    btn.forEach((el)=>{
      el.classList.remove('text-main')
    })
  }
  if (!dutyClassList) return <></>;
  return (
    <div className="table">
      <div className="row header">
        <div className="w-4/12 ml-4">제목</div>
        <div className="w-2/12 justify-center">수강자 수</div>
        <div className="w-4/12 justify-center">수강자 목록</div>
        <div className="w-2/12 justify-center">상태</div>
      </div>
      {dutyClassList[0].map((item, idx) => (
        <div className="row" key={idx}>
          <div className="w-4/12 ml-4 cursor-pointer" onClick={() => setWritePopup(item)}>
              {item.name}
          </div>
          <div className="w-2/12 justify-center">
            {(item as any).students_count}명
          </div>
          <div className="w-4/12 justify-center">
            <button
              className={`range_btn_${idx}`}
              data-month="1"
              onClick={(e)=>{
                setDateAction(e.target)
              }}>

              1개월
            </button>
            <button
              className={`range_btn_${idx}`}
              data-month="3"
              onClick={ (e)=>{
              setDateAction(e.target)
            }
            }>
              3개월
            </button>
            <button
              className={`range_btn_${idx}`}
              data-month="6"
              onClick={ (e)=>{
              setDateAction(e.target)
            }
            }>
              6개월
            </button>
            <span className="mr-3 ml-3">/</span>
            <p>
              <input
                type="date"
                name={`range_${idx}[]`}
                onClick={ ()=>{
                  hideBtnAction(idx)
                }}
              />

              <input
                type="date"
                name={`range_${idx}[]`}
                onClick={ ()=>{
                  hideBtnAction(idx)
                }}
              />
              </p>
            <i
              className="ii ii-import text-main text-lg cursor-pointer"
              onClick={async () => {
                exportExcel(item, idx);
              }}
            ></i>
          </div>
          {/*<div className="w-1/12 justify-center">*/}
          {/*  <button onClick={() => setDeletePopup(item)}>삭제</button>*/}
          {/*</div>*/}
          <div className="w-2/12 justify-center">
            노출
          </div>
        </div>
      ))}
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={dutyClassList[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
      {deletePopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteLectureCourse(
                (deletePopup as any).course_id
              );
              alert("삭제되었습니다");
              setDeletePopup(null);
              window.location.reload();
            } catch {
              alert("에러가 발생하였습니다");
            }
          }}
          close={() => setDeletePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
};

export default DutyClassTable;
